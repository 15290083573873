<template lang="pug">

div.records-nav.level.is-mobile
    div.level-item.is-justify-content-flex-start
      div.buttons
        button.button.is-link.is-small(
          @click="$emit('previous')"
          :disabled="first === 0"
        )
          span.icon
            i.fas.fa-angle-left
          span Previous 
        
        button.button.is-link.is-small(
          @click="$emit('next')"
          :disabled="last === totalCount"
        )
          span Next
          span.icon
            i.fas.fa-angle-right
    div.level-item.is-justify-content-flex-end(v-if="showFormButtons")
      div.buttons
        button.button.is-secondary.is-inverted.is-small(@click="$emit('filter')") 
          span.icon
            i.fas.fa-filter
          span Filters

        button.button.is-secondary.is-inverted.is-small(@click="$emit('edit')") 
          span.icon
            i.fas.fa-edit
          span Edit

</template>

<script>
export default {
  name: "RecordNav",
  props: {
    first: Number,
    last: Number,
    totalCount: Number,
    showFormButtons: {
      type: Boolean,
      default: true,
    }
  },
  emits: ['edit', 'filter', 'previous', 'next'],
}
</script>

<style lang="scss" scoped>

</style>