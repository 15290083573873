<template lang="pug">

main-app-layout
  template(#title)
    h1.title.is-3 Reset Password
  section.section
    div.columns.is-desktop
      
      div.column.is-offset-one-quarter.is-half
        div.field
          label.label Your email
          div.control.has-icons-left
            input.input(
              type="email" required v-model.trim="email",
              placeholder="Use your account email"
            )
            span.icon.is-left
              i.fas.fa-envelope
          p.help.is-danger(v-if="helpText") {{ helpText }}

        div.field
          div.control
            button.button.is-primary(@click="submit") Submit
      
</template>

<script>
import { resetPassword } from "@/api/users";

export default {
  name: "PasswordResetView",
  props: {
    msg: String
  },
  data() {
    return {
      email: null,
      helpText: null,
    };
  },
  computed: {
    isEmailValid() {
      return (this.email.length >= 5) 
    },
  },
  methods: {
    async submit() {
      if (!this.isEmailValid) {
        this.helpText = 'Email is not valid.'
        return
      }
      try {
        await resetPassword({
          email: this.email
        });
        this.$router.push({ name: "logIn" })
      } catch (error) {
        let errText = "Error. ";
        if (error) {
          errText += error;
        }
        this.helpText = errText;
      }
    }
  }
};
</script>