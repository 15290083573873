import { createStore, createLogger } from "vuex";
import nvr from "@/store/modules/nvr";
import users from "@/store/modules/users";
import core from "@/store/modules/core";
import wsAdapter from "@/utils/ws";
import { NVREventTypes, NVRMutationTypes, UsersEventTypes, UsersGetterTypes, UsersMutationTypes } from "@/store/defs";


const ws = wsAdapter;
ws.connect()

function createWebSocketPlugin(ws) {
  return (store) => {
    ws.on('data', (event) => {
      const data = JSON.parse(event.data);
      console.info('WS data:', data);
      const msg = data.message;
      const key = msg.message_key;
      
      if (key === UsersEventTypes.ME_UPDATED) {
        store.commit(UsersMutationTypes.SET_ME, msg.payload);
        const locale = store.getters[UsersGetterTypes.SETTINGS].locale;
        store.$app.$i18n.locale = locale;
      } else if (key === NVREventTypes.PIPELINE_UPDATED) {
        // store.commit(NVRMutationTypes.SET_NVR_PIPELINE, msg.payload)
      } else if (key === NVREventTypes.LIVE_SHARE_UPDATED) {
        store.commit(NVRMutationTypes.SET_GRANTED_LIVE_SHARE, msg.payload)
      }
      else if (key === NVREventTypes.LIVE_SHARE_DESTROYED) {
        store.commit(NVRMutationTypes.REMOVE_GRANTED_LIVE_SHARE, msg.payload.id)
      }
    });
  };
};


const debug = process.env.NODE_ENV !== "production";
const plugins = [createWebSocketPlugin(ws)];
// if (debug) {
//   plugins.push(createLogger());
// }

const store = createStore({
  modules: {
    nvr,
    users,
    core,
  },
  strict: debug,
  plugins,
});

export default store;

