<template lang="pug">
main-app-layout(navLeftAction="profile")
  
  template(#title)
    h1.title.is-3 Live Streams
  
  section.section
    div.message.is-info.is-small(v-if="!pipelines && showInfo") 
      div.message-header
        p Info
        button.delete(aria-label="delete" @click="showInfo=false")
      div.message-body
        h4.title.is-5 No live streams 
          i.icon
            span.fas.fa-meh
          span .
          span  Please, create some!
        
      
    button.button.is-primary(@click="showPipelineModal=true")
      i.icon
        span.fas.fa-plus
      span Create
    nvr-pipeline-create-form(
      v-if="showPipelineModal"
      :isActive="showPipelineModal" 
      @close="showPipelineModal=false"
    )

    nvr-pipeline-list

    nvr-granted-live-share-list Shared streams

</template>

<script>
import NvrPipelineList from "@/components/nvr/PipelineList.vue";
import NvrGrantedLiveShareList from "@/components/nvr/GrantedLiveShareList.vue";
import NvrPipelineCreateForm from "@/components/nvr/PipelineCreateForm";

export default {
  name: "LiveMonitors",
  components: {
    NvrPipelineList,
    NvrGrantedLiveShareList,
    NvrPipelineCreateForm,
  },
  data() {
    return {
      showPipelineModal: false,
      showInfo: true,
    }
  },
  computed: {
    pipelines() {
      return this.$store.getters.pipelines
    }
  }
};
</script>
