import request from "@/utils/request";
import endpoints from "@/api/endpoints";

// Pipelines

export async function listPipelines(params) {
  const res = await request.get(endpoints.NVR_PIPELINES, {
    params: params,
  });
  return res.data
}

export async function createPipeline(data) {
  const res = await request.post(endpoints.NVR_PIPELINES, data);
  return res.data
}

export async function updatePipeline(id, data) {
  const res = await request.patch(endpoints.NVR_PIPELINE.format(id), data);
  return res.data
}

export async function destroyPipeline(id) {
  const res = await request.delete(endpoints.NVR_PIPELINE.format(id));
  return res.data
}

// Live Shares

export async function listGrantedLiveShares() {
  const res = await request.get(endpoints.NVR_GRANTED_LIVE_SHARES);
  return res.data
}

export async function listLiveShares() {
  const res = await request.get(endpoints.NVR_LIVE_SHARES);
  return res.data
}

export async function createLiveShare(data) {
  const res = await request.post(endpoints.NVR_LIVE_SHARES, data);
  return res.data
}

export async function updateLiveShare(id, data) {
  const res = await request.patch(endpoints.NVR_LIVE_SHARE.format(id), data);
  return res.data
}

export async function destroyLiveShare(id) {
  const res = await request.delete(endpoints.NVR_LIVE_SHARE.format(id));
  return res.data
}

// Records

export async function listRecords(params) {
  if (!params) params = {}
  const res = await request.get(endpoints.NVR_RECORDS, {
    params: params,
  });
  let items = []
  let count = null
  if (params.limit) {
    items = res.data['results']
    count = res.data['count']
  } else {
    items = res.data
    count = data.length
  }
  return [items, count]
}

export async function updateRecord(id, data) {
  const res = await request.patch(endpoints.NVR_RECORD.format(id), data);
  return res.data
}

export async function destroyRecord(id) {
  const res = await request.delete(endpoints.NVR_RECORD.format(id));
  return res.data
}

// Sources

export async function checkSource(uri) {
  const res = await request.get(endpoints.NVR_SOURCES_CHECK, { params: { uri: uri } });
  return res.data
}

// Exports

export default {
  listPipelines,
  createPipeline,
  updatePipeline,
  destroyPipeline,

  listGrantedLiveShares,
  listLiveShares,
  createLiveShare,
  updateLiveShare,
  destroyLiveShare,
  
  listRecords,
  updateRecord,
  destroyRecord,

  checkSource,
};
