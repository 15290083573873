<template lang="pug">
span.icon
  i.fas(:class="iconClass")
</template>

<script>
export default {
  name: "Icon",
  props: {
    name: String
  },
  computed: {
    iconClass() {
      return 'fa-' + this.name
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~/node_modules/bulma/sass/utilities/_all.sass";

.icon.is-on svg {
  color: $primary !important;
}
</style>