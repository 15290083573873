<template lang="pug">

div.modal(
  :class="{ 'is-active': isActive }" 
)
  div.modal-background
  div.modal-content
    div.field.box
      h2.title.is-4(v-if="hasRecord") 
        | {{ record.pipelineName }}: {{ record.id }}
    
    div.field
      player-hls(
        v-if="hasRecord"
        :uri="uri"
        :hasControls="true"
        :isLive="false"
        :shouldPlay="true"
        :isMute="false"
      )
    div.box.buttons.is-centered
      button.button.is-link(@click="$emit('previous')")
        icon(name="angle-left")
        span Previous 
  
      button.button.is-link(@click="$emit('next')")
        span Next
        icon(name="angle-right")

      button.button.is-secondary(@click="$emit('stop')")
        span Stop
        
    button.modal-close.is-large(aria-label="close" @click="$emit('stop')")

</template>

<script>
import { Record } from "@/models/nvr";
import PlayerHls from "@/components/nvr/PlayerHls.vue";

export default {
  name: "RecordPlayerModal",
  components: {
    PlayerHls,
  },
  props: {
    record: Record,
    isActive: Boolean,
  },
  emits: ['previous', 'next', 'stop'],
  data() {
    return {

    }
  },
  computed: {
    hasRecord() {
      return (this.record !== null)
    },
    uri() {
      // console.info(this.record.getResourcePath('main'))
      return this.record.getResourcePath('main')
    },
  },
}

</script>