<template lang="pug">

div
  record-edit-form.field(
    v-if="showEditForm"
    :checkedRecords="checkedRecords"
    @check="toggleCheck"
    @delete="deleteRecords"
    @lock="lockRecords"
    @unlock="unlockRecords"
  )

  ul.field.level.is-mobile.records-list.is-flex-wrap-wrap(ref="list")
    li.level-item(v-for="record in records" :key="record.id")
      record-item(
        :isEditing="showEditForm" 
        :record="record" 
        :checkedRecords="checkedRecords"
        @play="playRecord"
        @check="onCheck"
      )

</template>

<script>
import { NVRActionTypes } from "@/store/defs";
import RecordItem from "@/components/nvr/RecordItem.vue";
import RecordEditForm from "@/components/nvr/RecordEditForm";

export default {
  name: "RecordList",
  components: {
    RecordItem,
    RecordEditForm,
  },
  emits: ['play', 'previous', 'next'],
  props: {
    records: Array,
    limit: Number,
    offset: Number,
    totalCount: Number,
    showEditForm: Boolean,
  },
  data() {
    return {
      checkedRecords: []
    } 
  },
  computed: {
  },
  beforeMount() {
  },
  methods: {
    onCheck(checkedRecords) {
      // console.info(checkedRecords)
      this.checkedRecords = checkedRecords
    },
    playRecord(record) {
      this.$emit('play', record)
    },
    toggleCheck() {
      if (this.records.length === this.checkedRecords.length) {
        this.checkedRecords = []
      } else {
        this.checkedRecords = this.records.filter(x => x)
      }
    },
    lockRecords() {
      const records = this.checkedRecords.filter(r => r.isLocked === false)
      for (const record of records) {
        this.$store.dispatch(NVRActionTypes.UPDATE_RECORD, {
          id: record.id,
          data: { is_locked: true }
        })
      }
      this.checkRecords = []
    },
    unlockRecords() {
      const records = this.checkedRecords.filter(r => r.isLocked === true)
      for (const record of records) {
        this.$store.dispatch(NVRActionTypes.UPDATE_RECORD, {
          id: record.id,
          data: { is_locked: false }
        })
      }
      this.checkRecords = []
    },
    deleteRecords() {
      for (const record of this.checkedRecords) {
        this.$store.dispatch(NVRActionTypes.DESTROY_RECORD, {
          id: record.id
        })
      }
      this.checkRecords = []
    },
    
  }
}
</script>

<style lang="scss" scoped>
@import "~/node_modules/bulma/sass/utilities/_all.sass";

.records-list {
  li {
    width: 48%;
  }
  // @include desktop {
  //   li {
  //     width: 32%;
  //   }
  // }
  .record-item {
    width: 100%;
  }
}

</style>