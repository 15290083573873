<template lang="pug">
// records checkbox all/none control
div
  div.level.records-edit-nav.is-mobile
    div.level-item.is-justify-content-flex-start
      span.records-checkbox
        label.checkbox.is-normal
          input(type="checkbox" @click="$emit('check')") 
          |  all
    div.level-item.is-justify-content-flex-end
      span.buttons
        button.button.is-danger.is-small(
          @click="$emit('delete')"
          :disabled="!checkedRecords.length"
        ) 
          span.icon
            i.fas.fa-trash
          span Delete

        button.button.is-link.is-inverted.is-small(
          :disabled="!checkedRecords.length"
          @click="$emit('lock')"
        ) 
          span.icon
            i.fas.fa-lock
          span Lock
        button.button.is-link.is-inverted.is-small(
          @click="$emit('unlock')"
          :disabled="!checkedRecords.length"
        ) 
          span.icon
            i.fas.fa-lock-open
          span Unlock

</template>

<script>
export default {
  name: "RecordEditForm",
  props: {
    checkedRecords: Array,
  },
  emits: ['check', 'lock', 'unlock', 'delete'],
}
</script>

<style lang="scss" scoped>
.records-edit-nav {

}

</style>