// Core

export const CoreMutationTypes = {
  SET_CSRF_TOKEN: "CORE_SET_CSRF_TOKEN",
  SET_HAS_AUTHED: "CORE_SET_HAS_AUTHED",
  SET_APP_FEEDBACK: "CORE_SET_APP_FEEDBACK",
  REMOVE_APP_FEEDBACK: "CORE_REMOVE_APP_FEEDBACK",
};

export const CoreActionTypes = {
  RETRIEVE_CSRF_TOKEN: "CORE_RETRIEVE_CSRF_TOKEN",
  RETRIEVE_SESSION: "CORE_RETRIEVE_SESSION",
  LOG_OUT: "CORE_LOG_OUT",
  LOG_IN: "CORE_LOG_IN",
};

// NVR

export const NVRMutationTypes = {
  SET_PIPELINES: "NVR_SET_PIPELINES",
  SET_PIPELINE: "NVR_SET_PIPELINE",
  REMOVE_PIPELINE: "NVR_REMOVE_PIPELINE",
  
  SET_GRANTED_LIVE_SHARES: "NVR_SET_GRANTED_LIVE_SHARES",
  SET_GRANTED_LIVE_SHARE: "NVR_SET_GRANTED_LIVE_SHARE",
  REMOVE_GRANTED_LIVE_SHARE: "NVR_REMOVE_GRANTED_LIVE_SHARE",

  SET_LIVE_SHARES: "NVR_SET_LIVE_SHARES",
  SET_LIVE_SHARE: "NVR_SET_LIVE_SHARE",
  REMOVE_LIVE_SHARE: "NVR_REMOVE_LIVE_SHARE",

  SET_RECORDS: "NVR_SET_RECORDS",
  SET_RECORDS_COUNT: "NVR_SET_RECORDS_COUNT",
  SET_RECORD: "NVR_SET_RECORD",
  REMOVE_RECORD: "NVR_REMOVE_RECORD",
};

export const NVRActionTypes = {
  LIST_PIPELINES: "NVR_LIST_PIPELINES",
  CREATE_PIPELINE: "NVR_CREATE_PIPELINE",
  UPDATE_PIPELINE: "NVR_UPDATE_PIPELINE",
  DESTROY_PIPELINE: "NVR_DESTROY_PIPELINE",

  LIST_GRANTED_LIVE_SHARES: "NVR_LIST_GRANTED_LIVE_SHARES",
  
  LIST_LIVE_SHARES: "NVR_LIST_LIVE_SHARES",
  CREATE_LIVE_SHARE: "NVR_CREATE_LIVE_SHARE",
  UPDATE_LIVE_SHARE: "NVR_UPDATE_LIVE_SHARE",
  DESTROY_LIVE_SHARE: "NVR_DESTROY_LIVE_SHARE",

  LIST_RECORDS: "NVR_LIST_RECORDS",
  UPDATE_RECORD: "NVR_UPDATE_RECORD",
  DESTROY_RECORD: "NVR_DESTROY_RECORD",
};

export const NVREventTypes = {
  PIPELINE_UPDATED: "NVR_PIPELINE_UPDATED",
  LIVE_SHARE_UPDATED: "NVR_LIVE_SHARE_UPDATED",
  LIVE_SHARE_DESTROYED: "NVR_LIVE_SHARE_DESTROYED",
};

// Users

export const UsersGetterTypes = {
	SETTINGS: "USERS_SETTINGS",
};

export const UsersActionTypes = {
	RETRIEVE_ME: "USERS_RETRIEVE_ME",
  UPDATE_ME: "USERS_UPDATE_ME",
};

export const UsersMutationTypes = {
	SET_ME: "USERS_SET_ME",
};

export const UsersEventTypes = {
  ME_UPDATED: "USERS_ME_UPDATED",
};