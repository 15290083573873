<template lang="pug">
div
  div.field.video-wrapper
    video(
      class="video-js" id="playerHls" 
      :controls="hasControls" 
      :playsinline="!hasControls" 
      :class="{'no-controls': !hasControls}"  
      ref="video" 
      crossorigin="anonymous"
    ) 
    //- button.button.close-btn Close
  div.level
    div.level-item.field
      div.control
        div.buttons
          button.button.is-primary(@click="togglePlay") 
            icon(:key="playText" :name="playText")
          button.button.is-primary(@click="enterFullscreen") 
            icon(name="expand")
</template>

<script> 
import Hls from "hls.js"

export default {
  name: "HlsPlayer",
  props: {
    uri: {
      type: String,
      default: null,
    },
    isMute: {
      type: Boolean,
      default: true
    },
    shouldPlay: {
      type: Boolean,
      default: true,
    },
    hasControls: {
      type: Boolean,
      default: false
    },
    isLive: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      player: null,
      isPlaying: false
    };
  },
  computed: {
    playText() {
      return this.isPlaying ? "pause" : "play"
    }
  },
  mounted() {
    this.play(this.uri)
    this.setIsPlaying()
  },
  beforeUnmount() {
    this.stop()
  },
  methods: {
    setIsPlaying() {
      this.isPlaying = !this.$refs.video.paused
    },
    togglePlay() {
      if (this.isPlaying) {
        this.pause()
      } else {
        this.resume()
      }
    },
    play(uri) {
      if (!uri) {
        return
      }
      if (this.player) {
        this.stop()
      }
      if (Hls.isSupported()) {
        this.player = new Hls();
        this.player.attachMedia(this.$refs.video);
        this.player.loadSource(uri);
      } else if (this.$refs.video.canPlayType('application/vnd.apple.mpegurl')) {
        this.player = {
          destroy() {

          }
        }
        this.$refs.video.src = uri;
      } else {
        logger.error('Cannot play HLS. HLS.js is not supported.')
        return
      }

      this.$refs.video.muted = this.isMute
      this.$refs.video.controls = this.hasControls
      this.$refs.video.play()
      this.setIsPlaying()
    },
    resume() {
      if (this.player) {
        this.$refs.video.play()
        this.setIsPlaying()
      }
    },
    pause() {
      if (this.player) {
        this.$refs.video.pause()
        this.setIsPlaying()
      }
    },
    stop() {
      if (this.player) {
        this.pause()
        this.player.destroy();
        this.player = null;
        this.setIsPlaying()
      }
    },
    enterFullscreen() {
      const el = this.$refs.video;
      if (el.requestFullscreen) 
        el.requestFullscreen();
      else if (el.webkitRequestFullscreen) 
        el.webkitRequestFullscreen();
      else if (el.msRequestFullScreen) 
        el.msRequestFullScreen();
    }
  }
}

</script>


<style lang="scss" scoped>

.no-controls {
  pointer-events: none;
}

.video-wrapper {
  position: relative;
  .close-btn {
    position: absolute;
    top: 0; left: 0;
  }
}



</style>