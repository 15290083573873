<template lang="pug">
span.time-field
  span.select
    select(
      :value="_time.hour" 
      @input="updateHour($event.target.value)"
    )
      option(v-for="h in [...Array(24).keys()]") {{ h }}
  
  span &ensp;:&ensp;
  
  span.select
    select(
      :value="_time.minute" 
      @input="updateMinute($event.target.value)"
    )
      option(v-for="m in [...Array(60).keys()]") {{ m }}
</template>


<script>
export default {
  name: "TimeField",
  props: {
    time: Object,
  },
  emits: ['update:time'],
  computed: {
    _time: {
      get() {
        return _.cloneDeep(this.time)
      },
      set(val) {
        const time = this._time
        Object.assign(time, val)
        this.$emit('update:time', time)
      }
    }
  },
  methods: {
    updateMinute(val) {
      this._time = { minute: Number(val) }
    },
    updateHour(val) {
      this._time = { hour: Number(val) }
    }
  }
}
</script>