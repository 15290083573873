export default {
  CORE_CSRF: "/core/auth/csrf/",
  CORE_LOG_IN: "/core/auth/session/login/",
  CORE_LOG_OUT: "/core/auth/session/logout/",
  CORE_SESSION: "/core/auth/session/",
  NVR_PIPELINES: "/nvr/pipelines/",
  NVR_PIPELINE: "/nvr/pipelines/{0}/",
  NVR_LIVE_SHARES: "/nvr/live_shares/",
  NVR_GRANTED_LIVE_SHARES: "/nvr/live_shares/granted/",
  NVR_LIVE_SHARE: "/nvr/live_shares/{0}/",
  NVR_RECORDS: "/nvr/records/",
  NVR_RECORD: "/nvr/records/{0}/",
  NVR_SOURCES_CHECK: "/nvr/sources/check/",
  USERS_ME: "/users/me/",
  USERS_SET_PASSWORD: "/users/set_password/",
  USERS_RESET_PASSWORD: "/users/reset_password/",
  USERS_CONFIRM_RESET_PASSWORD: "/users/reset_password_confirm/",
}   