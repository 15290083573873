<template lang="pug">

div.level.record-item
  div.level-item.record-checkbox(v-if="isEditing")
    input(
      type="checkbox" 
      :value="record"
      v-model="checkedRecords"
      @change="change"
    )
  div.level-item.record-img
    div(
      class="image" 
      :style="{ 'background-image': thumbnailUri}" 
      @click="$emit('play', record)"
    )
  div.record-text.level-item
    div.content
      p.tag.is-primary.is-ligh {{ record.pipelineName }}: {{ record.id }}
      p.is-size-7 {{ startedAt }}
  
  div.record-lock-icon.level-item
    span.icon(:key="lockIcon")
      i.fas(:class="lockIcon")

</template>

<script>
import { Record } from "@/models/nvr";
import { getLocalDatetime } from "@/utils";

export default {
  name: "RecordsView",
  props: {
    record: Record,
    checkedRecords: Array,
    isEditing: {
      type: Boolean,
      default: false,
    },
  },  
  emits: ['play', 'check'],
  // data() {
  //   return {
  //     isChecked: false,
  //   }
  // },
  mounted() {
    
  },
  computed: {
    lockIcon() {
      return this.record.isLocked ? 'fa-lock' : 'fa-lock-open';
    },
    thumbnailUri() {
      return 'url(' + this.record.getResourcePath('frame') + ')';
    },
    videoUri() {
      return this.record.getResourcePath('main');
    },
    startedAt() {
      return getLocalDatetime(this.record.startedAt)
    }
  },
  methods: {
    change() {
      // console.info(this.checkedRecords)
      // if (this.checkedRecords) {
      //   console.info(this.checkedRecords[0])
      // }
      this.$emit('check', this.checkedRecords)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~/node_modules/bulma/sass/utilities/_all.sass";

.record-item {
  min-height: 120px;
  margin-top: 1rem;
  background:white;

  .level-item {
    height: 100%;
    text-align: center;
    margin-right: 0 !important;
  }

  .content {
    width: 100%;
    text-align: center;
  }

  @include mobile {
    .content {
      width: 100%;
      text-align: center;
    }
  }
}

.record-checkbox {
  flex: 0 0 auto; 
}

.record-img {
  height: 100%;
  flex: 1 1 auto; 

  .image {
    background-position: center;
    background-size: cover;
    height: 120px;
    width: 160px
  }

  @include mobile {
    .image {
      width: 100%;
      max-width: 320px;
      height: 240px;
    }
  }

  @include from($tablet) {
    justify-content: flex-start;
    text-align: left;
  } 
}

.record-text {
  flex: 2 1 30%; 

  @include from($tablet) {

    .content {
      // background: red;
      justify-content: flex-start;
      text-align: left !important;
    }
  } 
}

.record-lock-icon {
  flex: 1 1 auto; 
}


</style>