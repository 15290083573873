<template lang="pug">
div(v-if="liveShare")
  div.level
    h3.title.is-5 \#{{ idx +1 }}
    div.buttons
      button.button.is-danger.is-inverted(@click="$emit('delete')")
        icon(name="trash")
  
  div.table-container
    table.table
      tbody
        tr
          th Owner 
          td {{ liveShare.ownerFullName }}
        tr
          th Username 
          td {{ liveShare.ownerUsername }}
        tr
          th Email
          td {{ liveShare.ownerEmail }}
        tr
          th Periods  
          td(v-if="!showPeriodsForm")
            div.level.mb-0
              div.level-item
                table.table(v-if="viewPeriodsAsDayTimes.length > 0")
                  thead
                    tr
                      th start
                      th
                      th end
                  tbody
                    tr.m-2(v-for="period in viewPeriodsAsDayTimes")
                      td {{ period.start }} 
                      td  - 
                      td {{ period.end }}
                            
              div.level-item.ml-4
                button.button(@click="openPeriodsForm")
                  span Edit 
          td(v-else)
            div.field
              div.buttons.control
                button.button.is-primary.is-small(@click="savePeriodsForm") 
                  span Save
                button.button.is-small(@click="closePeriodsForm") 
                  span Close
              div.field
                div.level.labels.is-align-content-flex-start
                  div.level-item Start
                  div.level-item End
                period-field.control(
                  v-for="(viewPeriod, idx) in viewPeriods.asDayTimes()" 
                  :key="idx"
                  :period="viewPeriod"
                  @update:period="event => editPeriod(idx, event)"
                )
                  button.delete(@click="deletePeriod(idx)")
              div.field
                div.control
                  button.button.is-primary.is-small(@click="addPeriod") 
                    icon(name="plus")
        tr
          th Mute 
            icon(name="microphone-slash")
          td 
            div.field
              input.switch(@click="toggleIsMute" type="checkbox" id="switchMute" :checked="isMute")
              label(for="switchMute")

        tr
          th On/Off
          td 
            div.field
              input.switch(@click="toggleIsEnabled" type="checkbox" id="switchEnabled" :checked="isEnabled")
              label(for="switchEnabled")

</template>

<script>
import { LiveShare } from "@/models/nvr";
import { NVRActionTypes } from "@/store/defs";
import PeriodField from "@/components/fields/PeriodField"
import _ from "lodash";


export default {
  name: "PipelineLiveShareListItem",
  components: {
    PeriodField,
  },
  props: {
    liveShare: LiveShare,
    idx: Number,
  },
  emits: ['delete'],
  data() {
    return {
      showPeriodsForm: false,
      viewPeriods: []
    }
  },
  beforeMount() {
    
  },
  computed: {
    isEnabled() {
      return this.liveShare.isEnabled
    },
    isMute() {
      return this.liveShare.isMute
    },
    isEnabledText() {
      return this.isEnabled ? "Enabled" : "Disabled"
    },
    viewPeriodsAsDayTimes() {
      return this.liveShare.viewPeriods.asReadableDayTimes()
    }
  },
  methods: {
    savePeriodsForm() {
      this.viewPeriods.save()
      const utcViewPeriods = this.viewPeriods.offsetToUTC();
      this.$store.dispatch(NVRActionTypes.UPDATE_LIVE_SHARE, {
        id: this.liveShare.id,
        data: { view_periods: utcViewPeriods }
      })
      this.closePeriodsForm()
    },
    openPeriodsForm() {
      this.viewPeriods = _.cloneDeep(this.liveShare.viewPeriods)
      this.showPeriodsForm = true
    },
    closePeriodsForm() {
      this.showPeriodsForm = false
    },
    addPeriod() {
      this.viewPeriods.addPeriod()
    },
    editPeriod(idx, value) {
      this.viewPeriods.editPeriod(idx, value)
    },
    deletePeriod(idx) {
      this.viewPeriods.removePeriod(idx)
    },
    async toggleIsEnabled() {
      await this.$store.dispatch(NVRActionTypes.UPDATE_LIVE_SHARE, {
        id: this.liveShare.id,
        data: { is_enabled: !this.isEnabled }
      })
    },
    async toggleIsMute() {
      await this.$store.dispatch(NVRActionTypes.UPDATE_LIVE_SHARE, {
        id: this.liveShare.id,
        data: { is_mute: !this.isMute }
      })
    },
  }
}
</script>


<style lang="scss" scoped>

.labels .level-item {
  width: 50%;
  text-align: left;
}

</style>

