<template lang="pug">
nav.navbar.is-transparent.is-fixed-top(
  role="navigation" aria-label="main navigation"
) 
  div.navbar-brand(
    class=""
  ) 
    a.navbar-item(
      v-if="hasBackButton"
      @click="goBack"
    ) 
      icon(name="caret-left")
      span Back
      
    a.navbar-item(
      href="https://naumcho.com"
    )
      img(:src="logoSrc")

    a.navbar-burger(
      v-if="hasAuthed"
      role="button" aria-label="menu" aria-expanded="false" 
      data-target="topNavbar" 
      ref="burger"
      @click="toggleMenu" 
    )
      span(aria-hidden="true")
      span(aria-hidden="true")
      span(aria-hidden="true")
    
  div.navbar-menu#topNavbar(v-if="hasAuthed" ref="topNavbar")
    div.navbar-start
      router-link.navbar-item(:to="{name: 'home'}") 
        | Home
      router-link.navbar-item(:to="{name: 'profile'}") 
        | Profile  
      router-link.navbar-item(v-if="isStaff" :to="{name: 'records'}") 
        | Records
    div.navbar-end
      hr
      a.navbar-item.has-text-danger(@click="onLogOut") 
        | Log out
      
</template>

<script>
import { CoreActionTypes } from "@/store/defs";

export default {
  props: {
    hasBackButton: {
      type: Boolean,
      default: false,
    },
    backButtonRoute: {
      type: String,
      default: 'home',
    },
  },
  computed: {
    hasAuthed() {
      return this.$store.state.core.hasAuthed
    },
    isStaff() {
      return this.$store.getters.isStaff
    },
    logoSrc() {
      return `${process.env.BASE_URL}icons/android-chrome-192x192.png`
    },
  },
  methods: {
    goBack() {
      this.$router.push({ name: this.backButtonRoute });
    },
    toggleMenu() {
      this.$refs.topNavbar.classList.toggle('is-active')
      this.$refs.burger.classList.toggle('is-active')
    },
    async onLogOut() {
      console.info('logout')
      try {
        await this.$store.dispatch(CoreActionTypes.LOG_OUT);
      } catch (error) {
        console.warn(error);
      }
      this.$router.push({ name: 'logIn' })
      .then(() => {
        window.location.reload();
      })
      .catch(console.error);
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar-menu hr {
  margin: 0.5rem 0;
}

</style>
