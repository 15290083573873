<template lang="pug">
main-app-layout(:hasBackButton="true")
  
  template(v-slot:title)
    h1.title.is-3 User Profile
    
  section.section.is-fullwidth
    div.section-item
      div(v-if="user")
        table.table
          tr
            th Username
            td {{ user.username }}
          tr
            th Email
            td {{ user.email }}
          tr(v-if="user.fullName.length > 0")
            th Name
            td {{ user.fullName }}

        //- div.field
        //-   p {{ $t("settings.locale") }}:
        //-   div.control
        //-     div.select.is-primary
        //-       select(v-model="locale")
        //-         option(
        //-           v-for="locale in $i18n.availableLocales"
        //-           :key="`locale-${locale}`" :value="locale"
        //-         ) {{ locale }}
    
    div.section-item
      
      div.field(v-if="!showPassForm")
        div.control(@click="showPassForm=!showPassForm")
          button.button.is-secondary.is-inverted
            i.icon
              span.fas.fa-edit
            span Change password
      div.field(v-if="showPassForm")
        set-password-form(@close="showPassForm=!showPassForm")

      div.field.mt-5
        div.control
          button.button.is-danger(@click="onLogOut")
            i.icon
              span.fas.fa-sign-out-alt
            span Log out
          
</template>

<script>
import { CoreActionTypes, UsersActionTypes } from "@/store/defs";
import SetPasswordForm from "@/components/users/SetPasswordForm"

export default {
  name: "ProvileView",
  components: {
    SetPasswordForm,
  },
  data() {
    return {
      showPassForm: false
    }
  },
  computed: {
    user() {
      return this.$store.state.users.me;
    },
    locale: {
      get() {
        return this.user.settings.locale;
      },
      async set(newValue) {
        await this.$store.dispatch(UsersActionTypes.UPDATE_ME, {
          data: {
            settings: {
              locale: newValue
            }
          }
        });
        this.$i18n.locale = newValue
      },
    },
  },
  async mounted() {
    await this.$store.dispatch(UsersActionTypes.RETRIEVE_ME);
  },
  methods: {
    async onLogOut() {
      try {
        await this.$store.dispatch(CoreActionTypes.LOG_OUT);
      } catch (error) {
        console.warn(error);
      }
      this.$router.push({ name: 'logIn' })
      .then(() => {
        window.location.reload();
      })
      .catch(console.error);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~/node_modules/bulma/sass/utilities/_all.sass";

.section-item {
  margin-top: 1.5rem;
  background: white;
  padding: 0.5rem 0.25rem;
  overflow: scroll;
}

</style>