<template lang="pug">
main-app-layout(navLeftAction="back")
  section.section
    div.card
      div.card-header
        h1.title.is-1.has-text-centered 404: Not Found
      div.card-content
        h2.title.is-2 Oopsie... 
</template>

<script>
export default {
  name: "NotFoundView",
};
</script>