import axios from "axios";
import store from "@/store";

const service = axios.create({
  baseURL: process.env.VUE_APP_HTTP_BACKEND,
  timeout: 6000,
});

const UNSAFE_METHODS = ["patch", "put", "post", "delete"];

service.interceptors.request.use(
  (config) => {
    // console.info(config.method)
    if (UNSAFE_METHODS.indexOf(config.method) > -1) {
      config.headers["X-Csrftoken"] = store.state.core.csrfToken;
    }
    return config;
  }
  // error => {
  //   // Handle request error here
  //   Promise.reject(error);
  // }
);

// response
service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // console.error(error)
    return Promise.reject(error.response);
  }
);

export default service;
