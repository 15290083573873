<template lang="pug">

div.modal(:class="{ 'is-active': isActive }")
  div.modal-background
  div.modal-card

    header.modal-card-head
      div.modal-card-title
        slot(name="header")
          
      button.delete(@click="$emit('close')" aria-label="close")

    section.modal-card-body
      div.content
        slot
    
    footer.modal-card-foot
      slot(name="footer")
        button.button.is-primary(
          aria-label="confirm" 
          @click="$emit('confirm')"
        ) Delete
        button.button(
          aria-label="close" 
          @click="$emit('close')"
        ) Cancel 

</template>


<script> 
export default {
  name: "CardModal",
  props: {
    isActive: Boolean,
  },
  emits: ['confirm', 'close'],
}

</script>
