import _ from "lodash";

export default class Model {
  static $store;

  clone() {
    return _.clone(this);
  }
}

