import _ from "lodash";

const print = console.log;

export class DayTime {

  constructor(start, end) {
    this.start = {
      hour: start.hour,
      minute: start.minute
    }
    this.end =  {
      hour: end.hour,
      minute: end.minute
    }
  }
}

const midnight = 23 * 60 + 59;
const day = 24 * 60;


export class Periods extends Array {

  addPeriod(dayTimes) {
    dayTimes = dayTimes || {
      start: { hour: 0, minute: 0 },
      end: { hour: 0, minute: 1 }
    }
    const period = [0, 1];
    this.unshift(period);
  }
  
  editPeriod(idx, dayTimes) {
    if (!Number.isInteger(idx) || typeof dayTimes !== 'object') {
      console.warn('Incorrect args.')
      return
    }
    const period = this._editPeriod(idx, dayTimes);
    this.splice(idx, 1, period);
    // this.normalize();
  }

  removePeriod(idx) {
    if (!Number.isInteger(idx)) {
      console.warn('Incorrect args.')
      return
    }
    return this.splice(idx, 1);
  }

  save() {
    this.normalize()
  }

  _editPeriod(idx, dayTimes) {
    if (typeof dayTimes !== 'object') {
      console.warn('Incorrect args.')
      return
    }
    const newPeriod = this.getPeriodFromDayTimes(dayTimes);
    print('Start', newPeriod[0], newPeriod[1])
    this.forEach((period, _idx) => {
      if (_idx === idx) {
        return 
      }
      if (newPeriod[0] >= period[0] && newPeriod[0] <= period[1]) {
        newPeriod[0] = period[1];
      }
      if (newPeriod[1] >= period[0] && newPeriod[1] <= period[1]) {
        newPeriod[1] = newPeriod[0] + 1;
      }
    });

    print('Mid', newPeriod[0], newPeriod[1])
    
    if (newPeriod[0] >= newPeriod[1]) {
      newPeriod[1] = newPeriod[0] + 1;
    }

    print('End', newPeriod[0], newPeriod[1])

    return newPeriod;
  }

  getPeriodFromDayTimes(dayTimes) {
    if (typeof dayTimes !== 'object') {
      console.warn('Incorrect args.')
      return
    }
    return [
      dayTimes.start.hour * 60 + dayTimes.start.minute,
      dayTimes.end.hour * 60 + dayTimes.end.minute,
    ];
  }

  offsetToUTC(inplace = false) {
    const offset = this.getTimezoneOffset();
    return this.offsetBy(offset, inplace);
  }

  offsetToLocalTZ(inplace = false) {
    const offset = this.getTimezoneOffset();
    return this.offsetBy(-offset, inplace);
  }

  offsetBy(offset, inplace = false) {
    if (!Number.isInteger(offset)) {
      console.warn('Incorrect args.')
      return
    }
    const periods = this.map(p => p.map(i => i + offset));
    const newPeriods = [];
    periods.forEach(p => {
      if (p[0] < 0 && p[1] >= 0) {
        newPeriods.push([p[0] + day, midnight]);
        newPeriods.push([0, p[1]]);
      }
      else if (p[0] <= midnight && p[1] > midnight) {
        newPeriods.push([p[0], midnight]);
        newPeriods.push([0, p[1] - day]);
      } else {
        newPeriods.push(p);
      }
    });
    if (this.inplace) {
      this.repopulate(newPeriods)
    } else {
      return periods
    }
  }

  getTimezoneOffset() {
    return new Date().getTimezoneOffset();
  }

  asDayTimes() {
    return this.map(p => {
      return {
        start: {
          hour: Math.floor(p[0] / 60),
          minute: p[0] % 60
        },
        end: {
          hour: Math.floor(p[1] / 60),
          minute: p[1] % 60
        }
      };
    });
  }

  asReadableDayTimes() {
    return this.map(p => {
      return {
        start: this.convertMinutesToHM(p[0]),
        end: this.convertMinutesToHM(p[1])
      };
    });
  }
  
  convertMinutesToHM(minutes) {
    if (!Number.isInteger(minutes)) {
      console.warn('Incorrect args.')
      return
    }
    let H = Math.floor(minutes / 60);
    let M = String(minutes % 60);
    M = M.length == 1 ? `0${M}` : M;
    let hm = `${H}:${M}`
    // hm += (H > 12) ? 'pm' : 'am';
    return hm;
  }

  normalize() {
    if (this.length <= 1) {
      return 
    }
    
    this.reorder();

    const newPeriods = []
    for (let idx = 0; idx < this.length; idx++) {
      
      if (idx === this.length - 1) {
        newPeriods.push([this[idx][0], this[idx][1]])
        break
      }

      const thisPeriodEnd = this[idx][1]
      const nextPeriodStart = this[idx + 1][0]

      if (thisPeriodEnd === nextPeriodStart
        || thisPeriodEnd === nextPeriodStart - 1
      ) {
        newPeriods.push([this[idx][0], this[idx + 1][1]])
        idx++
      } else {
        newPeriods.push([this[idx][0], this[idx][1]])
      }
    }
   
    this.repopulate(newPeriods)
  }

  reorder() {
    this.sort((a, b) => a[0] - b[0]);
  }

  repopulate(newPeriods) {
    if (!Array.isArray(newPeriods)) {
      console.warn('Incorrect args.')
      return
    }
    this.splice(0, this.length);
    while (newPeriods.length > 0) {
      this.push(newPeriods.shift());
    }
  }
}