<template lang="pug">
main-app-layout
  section.section
    div.columns.is-desktop 
      div.column.is-offset-one-quarter.is-half
        h1.title.is-1 Change Password
        div.field
          label.label New password
          div.control.has-icons-left
            input.input(
              type="password" v-model.trim="newPassword", placeholder="New password"
            )
            span.icon.is-left
              i.fas.fa-key
          p.help.is-danger(v-if="helpText") {{ helpText }}

        div.field
          div.control
            button.button.is-link(@click="resetPassword") Change

</template>

<script>
import { confirmResetPassword } from "@/api/users";

export default {
  name: "PasswordConfirmationView",
  props: {
    uid: String,
    token: String,
  },
  data() {
    return {
      newPassword: null,
      helpText: null,
    }
  },
  computed: {
    isPasswordValid() {
      return (this.newPassword.length >= 8)
    }
  },
  methods: {
    async resetPassword() {
      if (!this.isPasswordValid) {
        this.helpText = 'Password is too short. Should be at least 8 characters.'
        return
      }
      try {
        await confirmResetPassword({
          new_password: this.newPassword,
          uid: this.uid,
          token: this.token,
        });
        this.$router.push({ name: "logIn" })
      } catch(e) {
        this.helpText = 'Error, try again.'
      }
    }
  }
}
</script>