<template lang="pug">
div
  h2.title.is-4 Controls
  div.level.pipeline-controls.is-mobile
    div.level-left
      div.level-item
        button.button.is-secondary(@click="$emit('toggleLive')")
          icon(name="circle" :class="{'is-on': pipeline.doesLive}")
          span Live
      div.level-item
        button.button.is-secondary(@click="$emit('toggleRecording')")
          icon(name="circle" :class="{'is-on': pipeline.doesRecording}")
          span Record
    div.level-right
      div.level-item
        button.button.is-danger(@click="$emit('delete')")
          icon(name="trash")
          span Delete

</template>

<script> 
import { Pipeline } from "@/models/nvr";

export default {
  name: "PipelineControls",
  props: {
    pipeline: Pipeline,
  },
  emits: ['toggleLive', 'toggleRecording', 'delete'],
}

</script>

