import Model from "@/models";

export class User extends Model {
  constructor(data) {
    super();
    this.id = data.id;
    this.username = data.username;
    this.email = data.email;
    this.firstName = data.first_name;
    this.lastName = data.last_name;
    this.settings = data.settings;
    this.isStaff = data.is_staff;
  }

  get fullName() {
    return this.firstName + " " + this.lastName;
  }
}

export function getAnonUser() {
  const data = {
    id: 0,
    username: "anon",
    email: "",
    first_name: "",
    last_name: "",
    settings: {},
  };
  const user = new User(data);
  return user;
}
