<template lang="pug">

figure.image.is-4by3
  img(
    ref="image"
    alt="thumbnail"
    type="media/jpeg"
    crossorigin="anonymous"
    :src="imageSrc"
  )

</template>

<script>
var requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame ||
                            window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
var cancelAnimationFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame;

export default {
  name: "JpegPlayer",
  props: {
    uri: String,
  },
  data() {
    return {
      shouldRun: false,
      myReq: null,
      timePerFrame: 100,
      timeWhenLastUpdate: null,
      timeFromLastUpdate: null,
      imageSrc: `${process.env.BASE_URL}images/default_video_thumbnail.png`,
    };
  },
  async mounted() {
    this.shouldRun = true;
    this.myReq = await requestAnimationFrame(this.step);
  },
  unmounted() {
    cancelAnimationFrame(this.myReq);
    this.myReq = null;
    this.shouldRun = false;
  },
  methods: {
    setImageSrc() {
      const ts = Date.now();
      this.imageSrc = this.uri + `?ts=${ts}`;
    },
    async step(startTime) {
      if (!this.shouldRun) {
        return;
      }
      if (!this.timeWhenLastUpdate) {
        this.timeWhenLastUpdate = startTime;
      }
      this.timeFromLastUpdate = startTime - this.timeWhenLastUpdate;

      if (this.timeFromLastUpdate > this.timePerFrame) {
        this.setImageSrc()
        this.timeWhenLastUpdate = startTime;     
      }
      this.myReq = await requestAnimationFrame(this.step);
    }
  },
};
</script>

<style scoped>
@import "../../../node_modules/video.js/dist/video-js.min.css";

.image {
  width: 100%;
  background: black;
}

</style>
