<template lang="pug">
app-footer
</template>

<script>
import AppFooter from "@/components/nav/AppFooter";

export default {
  name: "StandardFooter",
  components: {
    AppFooter,
  },
};
</script>
