<template lang="pug">

card-modal(
  :isActive="isActive" 
  @close="$emit('close')"
)
  //- header
  template(#header) Share live stream 
  //- body
  div
    div.field
      p.is-warning.notification(v-if="formError") {{ formError }}
    div.field
      label.label Friend's Email
      div.control
        input.input(
          :disabled="isSubmitting"
          placeholder="user@domain.com"
          type="email" required
          v-model="email.value"
          @change="validateEmail"
        )
      p.is-danger.help(v-if="email.error") {{ email.error }}

    div.field
      label.label Mute (just video sharing)
      div.control
        input.switch(
          @click="isMute=!isMute" type="checkbox" 
          id="switchMute" :checked="isMute"
        )
        label(for="switchMute")

    div.field
      div.control.level
        .level-left
          .level-item
            label.label View periods
          .level-item
            button.button.is-primary.is-outlined(@click="addPeriod")
              icon(name="plus")
    div.field
      div.level.labels.is-align-content-flex-start
        div.level-item Start
        div.level-item End
      period-field.mt-4(
        v-for="(viewPeriod, idx) in viewPeriods.asDayTimes()" 
        :key="idx"
        :period="viewPeriod"
        @update:period="event => editPeriod(idx, event)"
      )
        button.delete(@click="deletePeriod(idx)")

  //- footer
  template(#footer)
    button.button.is-primary(
      aria-label="submit" @click="submit"
      :disabled="!isFormValid"
      :class="{ 'is-loading': isSubmitting }"
    ) Submit
    button.button(aria-label="close" @click="$emit('close')") Cancel

</template>

<script>
import { emailRegex } from "@/utils/validators";
import { Pipeline } from "@/models/nvr";
import { Periods } from "@/models/common";
import { NVRActionTypes } from "@/store/defs";
import PeriodField from "@/components/fields/PeriodField"
import _ from "lodash";

export default {
  name: "LiveShareCreateForm",
  components: {
    PeriodField,
  },
  props: {
    isActive: Boolean,
    pipeline: Pipeline
  },
  emits: ['close'],
  data() {
    return {
      email: {
        value: '',
        error: '',
        dirty: false,
        valid: false
      },
      locale: 'bg',
      timezone: 'UTC',
      isMute: true,
      viewPeriods: new Periods(),
      formError: '',
      isSubmitting: false
    }
  },
  computed: {
    isFormValid() {
      return this.email.valid;
    },
    periods() {
      return this.viewPeriods.asDayTimes()
    },  
    utcViewPeriods() {
      return this.viewPeriods.offsetToUTC(false)
    }
  },
  methods: {
    addPeriod() {
      this.viewPeriods.addPeriod()
    },
    editPeriod(idx, value) {
      this.viewPeriods.editPeriod(idx, value)
    },
    deletePeriod(idx) {
      this.viewPeriods.removePeriod(idx)
    },
    validateEmail() {
      this.email.dirty = true
      this.email.valid = emailRegex.test(this.email.value)
      this.email.error = this.email.valid ? '' : 'Should be a valid email.'
    },
    async createLiveShare() {
      try {
        this.viewPeriods.save()
        const data = {
          owner_email: this.email.value,
          pipeline: this.pipeline.id,
          view_periods: this.utcViewPeriods,
          is_mute: this.isMute,
        }
        console.info(data)
        await this.$store.dispatch(NVRActionTypes.CREATE_LIVE_SHARE, {
          data: data
        })
      } catch(e) {
        console.error(e)
        this.formError = `Error ${e.status} ${e.statusText}`
        if (e.data.email) {
          this.formError += " / User with this email wasn't found."
        }
      }
    },
    async submit() {
      if (!this.isFormValid) {
        return
      }
      this.isSubmitting = true
      this.formError = ''
      await this.createLiveShare() 
      this.isSubmitting = false
      this.$emit('close')
    }
    
  },
  // created() {
  //   // Debouncing with Lodash
  //   this.debouncedClick = _.debounce(this.validateEmail, 500)
  // },
  // unmounted() {
  //   // Cancel the timer when the component is removed
  //   this.debouncedClick.cancel()
  // },
}

</script>


<style lang="scss" scoped>

.labels .level-item {
  width: 50%;
  text-align: left;
  justify-content: left;
}


</style>

