<template lang="pug">

div.modal(:class="{ 'is-active': isActive }")
  div.modal-background
  div.modal-card

    section.modal-card-body
      slot(name="header")
        h2.title.is-2 Are you sure?
        
      div.content
        slot

      footer.buttons.level
        slot(name="footer")
          button.button.is-danger(
            aria-label="confirm" 
            @click="$emit('confirm')"
          ) Confirm
          button.button(
            aria-label="close" 
            @click="$emit('close')"
          ) Cancel 

  button.modal-close(@click="$emit('close')" aria-label="close")
</template>


<script> 
export default {
  name: "ConfirmationModal",
  props: {
    isActive: Boolean,
  },
  emits: ['confirm', 'close'],
}

</script>
