<template lang="pug">
div(v-if="pipelines.length")
  
  h3.title.is-3.mt-5
    slot

  ul.pipeline-list(
    class="level is-mobile mt-2"
  )
    li.pipeline-list-item(
      class="level-item mt-4"
      v-for="pipeline in pipelines"
      :key="pipeline.id"
    )
      pipeline-item(
        :pipeline="pipeline"
      )
  //- div.dummy
</template>

<script>
import PipelineItem from "@/components/nvr/PipelineItem.vue";

export default {
  name: "PipelineList",
  components: {
    PipelineItem,
  },
  computed: {
    pipelines() {
      return this.$store.state.nvr.pipelines;
    },
  },
};
</script>

<style lang="scss" scoped>
.pipeline-list {
  width: 100%;
  align-items: flex-start;
}

.pipeline-list-item {
  flex: 50% 1 1;
  
}

</style>