import api from "@/api/nvr";
import { NVRActionTypes, NVRMutationTypes } from "@/store/defs";
import { Pipeline, LiveShare, Record } from "@/models/nvr";
import _keyBy from "lodash/keyBy";
import { reactive } from 'vue';

const state = () => ({
  pipelines: [],
  liveShares: [],
  grantedLiveShares: [],
  records: [],
  recordsTotalCount: 0,
});

const getters = {
  pipelines: (state) => state.pipelines,
  pipeline(state) {
    return id => state.pipelines.find(i => i.id === id)
  },
  liveShares: (state) => state.liveShares,
  liveShare(state) {
    return id => state.liveShares.find(i => i.id === id)
  },
  grantedLiveShares: (state => state.grantedLiveShares),
  grantedLiveShare(state) {
    return id => state.grantedLiveShares.find(i => i.id === id)
  },
  records: (state, getters) => state.records,
  record(state) {
    return id => state.records.find(i => i.id === id)
  },
};

const mutations = {
  [NVRMutationTypes.SET_PIPELINES](state, dataItems) {
    const items = dataItems.map((data) => new Pipeline(data));
    state.pipelines = items
  },
  [NVRMutationTypes.SET_PIPELINE](state, data) {
    const item = new Pipeline(data);
    const items = state.pipelines
    const idx = items.findIndex(_item => _item.id === item.id);
    if (idx === -1) {
      items.push(item)
    } else {
      items.splice(idx, 1, item)
    }
  },
  [NVRMutationTypes.REMOVE_PIPELINE](state, id) {
    const items = state.pipelines
    const idx = items.findIndex(item => item.id === id);
    if (idx !== -1) {
      items.splice(idx, 1)
    }
  },

  // Granted Live Shares

  [NVRMutationTypes.SET_GRANTED_LIVE_SHARES](state, dataItems) {
    const items = dataItems.map(data => new LiveShare(data));
    state.grantedLiveShares = items;
  },
  [NVRMutationTypes.SET_GRANTED_LIVE_SHARE](state, data) {
    const item = new LiveShare(data);
    const items = state.grantedLiveShares
    
    const idx = items.findIndex(_item => _item.id === item.id);
    if (idx === -1) {
      items.push(item)
    } else {
      console.info(item.isEnabled)
      items.splice(idx, 1, item)
    }
  },
  [NVRMutationTypes.REMOVE_GRANTED_LIVE_SHARE](state, id) {
    const items = state.grantedLiveShares;
    const idx = items.findIndex(item => item.id === id);
    if (idx !== -1) {
      items.splice(idx, 1);
    }
  },

  // Live Shares

  [NVRMutationTypes.SET_LIVE_SHARES](state, dataItems) {
    const liveShares = dataItems.map(data => new LiveShare(data));
    state.liveShares = liveShares;
  },
  [NVRMutationTypes.SET_LIVE_SHARE](state, data) {
    const item = new LiveShare(data);
    const items = state.liveShares
    const idx = items.findIndex(_item => _item.id === item.id);
    if (idx === -1) {
      items.push(item)
    } else {
      items.splice(idx, 1, item)
    }
    
  },
  [NVRMutationTypes.REMOVE_LIVE_SHARE](state, id) {
    const items = state.liveShares;
    const idx = items.findIndex(item => item.id === id);
    if (idx !== -1) {
      items.splice(idx, 1);
    }
  },

  // Records

  [NVRMutationTypes.SET_RECORDS](state, dataItems) {
    const items = dataItems.map(data => new Record(data));
    state.records = items;
  },
  [NVRMutationTypes.SET_RECORDS_COUNT](state, count) {
    state.recordsTotalCount = count;
  },
  [NVRMutationTypes.SET_RECORD](state, data) {
    const item = new Record(data);
    const items = state.records;
    // const idx = Array(items).findIndex(_item => _item.id === item.idx);
    const idx = items.findIndex((_item) => {
      return _item.id === item.id
    });
    if (idx === -1) {
      items.push(item)
    } else {
      state.records.splice(idx, 1, item)
    }
  },
  [NVRMutationTypes.REMOVE_RECORD](state, id) {
    const items = state.records;
    const idx = items.findIndex(item => item.id === id);
    if (idx !== -1) {
      items.splice(idx, 1);
    }
  },
};

const actions = {
  // pipelines
  async [NVRActionTypes.LIST_PIPELINES]({ commit }) {
    const data = await api.listPipelines();
    commit(NVRMutationTypes.SET_PIPELINES, data);
  },
  async [NVRActionTypes.CREATE_PIPELINE]({ commit }, payload) {
    const data = await api.createPipeline(payload.data);
    commit(NVRMutationTypes.SET_PIPELINE, data);
  },
  async [NVRActionTypes.UPDATE_PIPELINE]({ commit }, payload) {
    try {
      const data = await api.updatePipeline(payload.id, payload.data);
      commit(NVRMutationTypes.SET_PIPELINE, data);
    } catch (e) {
      // console.warn('Response error', e.data);
    }
  },
  async [NVRActionTypes.DESTROY_PIPELINE]({ commit }, payload) {
    await api.destroyPipeline(payload.id);
    commit(NVRMutationTypes.REMOVE_PIPELINE, payload.id);
  },


  // live shares
  async [NVRActionTypes.LIST_GRANTED_LIVE_SHARES]({ commit }) {
    const data = await api.listGrantedLiveShares();
    commit(NVRMutationTypes.SET_GRANTED_LIVE_SHARES, data);
  },

  async [NVRActionTypes.LIST_LIVE_SHARES]({ commit }) {
    const data = await api.listLiveShares();
    commit(NVRMutationTypes.SET_LIVE_SHARES, data);
  },
  async [NVRActionTypes.CREATE_LIVE_SHARE]({ commit }, payload) {
    const data = await api.createLiveShare(payload.data);
    commit(NVRMutationTypes.SET_LIVE_SHARE, data);
  },
  async [NVRActionTypes.UPDATE_LIVE_SHARE]({ commit }, payload) {
    const data = await api.updateLiveShare(payload.id, payload.data);
    commit(NVRMutationTypes.SET_LIVE_SHARE, data);
  },
  async [NVRActionTypes.DESTROY_LIVE_SHARE]({ commit }, payload) {
    await api.destroyLiveShare(payload.id);
    commit(NVRMutationTypes.REMOVE_LIVE_SHARE, payload.id);
  },


  // records
  async [NVRActionTypes.LIST_RECORDS]({ commit }, payload) {
    const [data, count] = await api.listRecords(payload.params);
    commit(NVRMutationTypes.SET_RECORDS, data);
    commit(NVRMutationTypes.SET_RECORDS_COUNT, count)
  },
  async [NVRActionTypes.UPDATE_RECORD]({ commit }, payload) {
    const data = await api.createRecord(payload.data);
    commit(NVRMutationTypes.SET_RECORD, data);
  },
  async [NVRActionTypes.UPDATE_RECORD]({ commit }, payload) {
    const data = await api.updateRecord(payload.id, payload.data);
    commit(NVRMutationTypes.SET_RECORD, data);
  },
  async [NVRActionTypes.DESTROY_RECORD]({ commit }, payload) {
    await api.destroyRecord(payload.id);
    commit(NVRMutationTypes.REMOVE_RECORD, payload.id);
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
