import Model from "@/models";
import { Periods } from "@/models/common"
import _ from "lodash";

// deprecated
export class Stream extends Model {}
export class Account extends Model { }


export class Resource extends Model {
  constructor(data) {
    super()

    this.uuid = data.uuid;
    this.paths = data.paths;
    this.pipeline_uuid = data.pipeline_uuid
  }
}

export class LiveSource extends Resource {
  constructor(data) {
    super(data)
    
    this.baseUri = data.base_uri;
  }

  getResourcePath(pathKey='main') {
    let resourcePath = this.baseUri;
    const path = this.paths[pathKey];
    if (path) {
      resourcePath += path;
    }
    return resourcePath;
  }
}

export class LiveSink extends Resource {
  constructor(data) {
    super(data)
  }

  getResourcePath(pathKey='main') {
    const path = this.paths[pathKey]
    let baseUri = process.env.VUE_APP_NVR_URL + '/new';
    const resourcePath = `${baseUri}/${this.pipeline_uuid}/live/${this.uuid}/${path}`;
    return process.env.VUE_APP_HTTP_BASE_URL + resourcePath
  }
}

export class Record extends Resource {
  constructor(data) {
    super(data)
    this._startedAt = null
    this._finishedAt = null

    this.id = data.id;
    this.pipeline = data.pipeline;
    this.pipelineUUID = data.pipeline_uuid;
    this.pipelineName = data.pipeline_name
    this.type = data.type;
    this.isLocked = data.is_locked
    this.isArchived = data.is_archived;
    this.startedAt = data.started_at;
    this.finishedAt = data.finished_at;
    this.targetDuration = data.target_duration;
  }

  set startedAt(item) {
    this._startedAt = new Date(item);
  }

  get startedAt() {
    return this._startedAt;
  }

  set finishedAt(item) {
    this._finishedAt = new Date(item);
  }

  get finishedAt() {
    return this._finishedAt;
  }

  getResourcePath(pathKey='main') {
    const path = this.paths[pathKey]
    const subpath = this.isArchived ? '/archived' : '/new'
    let baseUri = process.env.VUE_APP_NVR_URL + subpath;
    const resourcePath = `${baseUri}/${this.pipeline_uuid}/records/${this.uuid}/${path}`;
    return process.env.VUE_APP_HTTP_BASE_URL + resourcePath
  }
}

export class LiveShare extends Model {
  constructor(data) {
    super();

    this._sink = null;
    this._canView = false;
    this._createdAt = null;
    this._updatedAt = null;

    this.id = data.id;
    this.pipeline_id = data.pipeline;
    this.pipeline_uuid = data.pipeline_uuid;
    this.ownerFullName = data.owner_full_name
    this.ownerUsername = data.owner_username
    this.ownerEmail = data.owner_email
    this.canView = data.can_view;
    this.name = data.name
    this.sink = data.sink;
    this.owner = data.owner;
    this.viewPeriods = Periods.from(data.view_periods).offsetToLocalTZ(true)
    this.isMute = data.is_mute;
    this.isEnabled = data.is_enabled;
    this.createdAt = data.created_at;
    this.updatedAt = data.updated_at;
    
  }

  set createdAt(item) {
    this._createdAt = new Date(item);
  }

  get createdAt() {
    return this._createdAt;
  }

  set updatedAt(item) {
    this._updatedAt = new Date(item);
  }

  get updatedAt() {
    return this._updatedAt;
  }

  set sink(item) {
    this._sink = new LiveSink(item);
  }

  get sink() {
    return this._sink
  }

  get timezoneOffset() {
    return new Date().getTimezoneOffset() / 60;
  }

  get currentTime() {
    const d = new Date();
    return 60 * d.getHours() + d.getMinutes();
  }

  get canView() {
    if (!this.isEnabled || !this._canView) {
      return false;
    }
    return this.isInViewPeriod;
  }

  set canView(canView) {
    this._canView = canView;
  }

  get isInViewPeriod() {
    if (this.viewPeriods.length === 0) {
      return true;
    }
    const time = this.currentTime;
    for (const [start, end] of this.viewPeriods) {
      if (time >= start && time < end) {
        return true;
      }
    }
    return false;
  }

}

export class Pipeline extends Model {
  constructor(data) {
    super();

    this._source = null
    this._sink = null
    this._shares = null
    this._createdAt = null;
    this._updatedAt = null;

    this.id = data.id;
    this.uuid = data.uuid;
    this.name = data.name;
    this.source = data.source;
    this.sink = data.sink;
    this.live_shares = data.live_shares;
    this.owners = data.owners
    this.isHealthy = data.is_healthy;
    this.doesLive = data.does_live;
    this.doesRecording = data.does_recording;
    this.createdAt = data.created_at;
    this.updatedAt = data.updated_at;
  }

  set shares(items) {
    this._shares = items.map(item => new LiveShare(item));
  }

  get shares() {
    return this._shares;
  }

  set source(item) {
    this._source = new LiveSource(item);
  }

  get source() {
    return this._source
  }

  set sink(item) {
    this._sink = new LiveSink(item);
  }

  get sink() {
    return this._sink
  }

  set createdAt(item) {
    this._createdAt = new Date(item);
  }

  get createdAt() {
    return this._createdAt;
  }

  set updatedAt(item) {
    this._updatedAt = new Date(item);
  }

  get updatedAt() {
    return this._updatedAt;
  }

}

export const MediaTypes = {
  LIVE: "live",
  REC: "rec",
}

export const ContentTypes = {
  VIDEO: "video",
  IMAGE: "image",
}

