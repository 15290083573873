import { createRouter, createWebHashHistory, createWebHistory } from "vue-router";
import store from "@/store"

import LiveMonitorsView from "@/views/LiveMonitors.vue";
import LiveMonitorDetailView from "@/views/LiveMonitorDetail.vue";
import PipelineDetailView from "@/views/PipelineDetail";
import RecordsView from "@/views/Records";
import ProfileView from "@/views/Profile.vue";
import LogInView from "@/views/LogIn";
import NotFoundView from "@/views/NotFound.vue";
import PasswordResetView from "@/views/PasswordReset.vue";
import PasswordConfirmationView from "@/views/PasswordConfirmation.vue";

import { CoreActionTypes } from '@/store/defs';

const routes = [
  {
    path: "",
    name: "home",
    component: LiveMonitorsView,
  },
  {
    path: "/nvr/watch/:type/:id/",
    name: "watch",
    component: LiveMonitorDetailView,
    props: true,
  },
  {
    path: "/nvr/pipelines/:id/",
    name: "pipeline",
    component: PipelineDetailView,
    props: true,
  },
  {
    path: "/nvr/records/",
    name: "records",
    component: RecordsView,
    props: true,
  },
  {
    path: "/users/profile/",
    name: "profile",
    component: ProfileView,
    // component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
  },
  {
    path: "/auth/login/",
    name: "logIn",
    component: LogInView,
  },
  {
    path: "/auth/password/reset/",
    name: "passwordReset",
    component: PasswordResetView,
  },
  {
    path: "/auth/password/reset/confirm/:uid/:token/",
    name: "passwordConfirmation",
    component: PasswordConfirmationView,
    props: true,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: NotFoundView,
  }

];

const createHistory = (process.env.NODE_ENV === 'production') 
  ? createWebHistory
  : createWebHashHistory;

const router = createRouter({
  history: createHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isAuthPage = to.fullPath.indexOf('/auth/') == 0;
  let hasAuthed = store.state.core.hasAuthed;
  if (hasAuthed === null) {
    await store.dispatch(CoreActionTypes.RETRIEVE_SESSION)
  }
  hasAuthed = store.state.core.hasAuthed;
  if (hasAuthed && isAuthPage) {
    next({ name: 'home' });
  } else if (!hasAuthed && !isAuthPage) {
    next({ name: 'logIn' });
  } else {
    next();
  }
});

export default router;
