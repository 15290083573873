<template lang="pug">

div.field
  label.label Current password
  div.control.has-icons-left
    input.input(type="password" v-model.trim="currentPassword")
    span.icon.is-left
      i.fas.fa-key

div.field
  label.label New password
  div.control.has-icons-left
    input.input(type="password" v-model.trim="newPassword")
    span.icon.is-left
      i.fas.fa-key
  p.help.is-danger(v-if="helpText") {{ helpText }}

div.field
  div.control
    div.buttons
      button.button.is-link(@click="resetPassword") Change
      button.button.is-secondary.is-inverted(
        @click="$emit('close')"
      )
        | Close
</template>

<script>

import { setPassword } from "@/api/users";

export default {
  name: "SetPasswordForm",
  emits: ['close'],
  data() {
    return {
      newPassword: null,
      currentPassword: null,
      helpText: null,
    }
  },
  computed: {
    isPasswordValid() {
      return ((this.newPassword.length >= 8) && (this.currentPassword.length >= 4)) 
    }
  },
  methods: {
    async resetPassword() {
      if (!this.isPasswordValid) {
        this.helpText = 'Inputs are not valid.'
        return
      }
      try {
        await setPassword({
          new_password: this.newPassword,
          current_password: this.currentPassword,
        });
        window.location.reload();
      } catch(e) {
        this.helpText = 'Error, try again.'
      }
    }
  }
}

</script>