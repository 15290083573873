export function getLocalDatetime(date) {
  return new Intl.DateTimeFormat(
    'en-GB', 
    { dateStyle: 'short', timeStyle: 'short' }
  ).format(date)
}

export function insertLocationQueryParam(key, value, locKey='search') {
  key = encodeURIComponent(key);
  value = encodeURIComponent(value);
  // kvp looks like ['key1=value1', 'key2=value2', ...]
  var kvp = document.location[locKey].substr(1).split('&');
  let i=0;

  for(; i<kvp.length; i++){
      if (kvp[i].startsWith(key + '=')) {
          let pair = kvp[i].split('=');
          pair[1] = value;
          kvp[i] = pair.join('=');
          break;
      }
  }

  if(i >= kvp.length){
      kvp[kvp.length] = [key,value].join('=');
  }

  if (kvp[0].indexOf('?') === -1) {
    kvp[0] += '?'
  }
  
  // can return this or...
  let params = kvp.join('&');
  // console.info(params)
  // console.info(document.location[locKey])
  // reload page with new params
  document.location[locKey] = params;
}

export function isElementVisible(el) {
    var rect     = el.getBoundingClientRect(),
        vWidth   = window.innerWidth || document.documentElement.clientWidth,
        vHeight  = window.innerHeight || document.documentElement.clientHeight,
        efp      = function (x, y) { return document.elementFromPoint(x, y) };     

    // console.info(vHeight)

    // console.info(rect)
    // Return false if it's not in the viewport
    if (rect.right < 0 || rect.bottom < 0 
            || rect.left > vWidth || rect.top > vHeight)
        return false;

    // Return true if any of its four corners are visible
    return (
          el.contains(efp(rect.left,  rect.top))
      ||  el.contains(efp(rect.right, rect.top))
      ||  el.contains(efp(rect.right, rect.bottom))
      ||  el.contains(efp(rect.left,  rect.bottom))
    );
}