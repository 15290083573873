<template lang="pug">

div.app-wrapper
  top-nav(:hasBackButton="hasBackButton")
  
  main
    div.container.is-max-desktop    
      div.top-section
        section.section
          div.container.is-max-desktop    
            div.title-content(class="mt-4")
              div.hola
                slot(name="title")
            
            //- div.secondary-nav(class="content" v-if="hasBackButton")
            //-   div.field
            //-     router-link(:to="{ name: 'home' }")
            //-       button.button.is-secondary.is-medium 
            //-         icon(name="caret-left")
            //-         span Back
              
            div.notification(v-if="feedback.text" class="is-light" :class="feedbackCls") 
              button.delete(@click="resetFeedback")
              | {{ feedback.text }}
      
      div.main-section
        slot

  bottom-nav

</template>

<script>
import TopNav from "@/components/nav/TopNav.vue";
import BottomNav from "@/components/nav/BottomNav.vue";
import { CoreMutationTypes } from "@/store/defs";

export default {
  name: "MainLayout",
  props: {
    hasBackButton: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    TopNav,
    BottomNav,
  },
  computed: {
    feedback() {
      return this.$store.state.core.feedback;
    },
    feedbackCls() {
      let level = ""
      switch (this.feedback.type) {
        case 'success':
          level = 'is-success';
          break
        case 'error':
          level = 'is-danger';
          break
        case 'warning':
          level = 'is-warning';
          break
        default:
          level = 'is-info';
      }
      return level
    }
  },
  methods: {
    resetFeedback() {
      this.$store.commit(CoreMutationTypes.REMOVE_APP_FEEDBACK);
    }
  }
};
</script>

<style lang="scss">

.main-section .section,
.top-section .section {
  padding: 1rem 0.5rem;

  &.is-fullwidth {
    padding: 1rem 0;
  }
}

.title-content {
  margin-bottom: 1.5rem;
}

.container {
  position: relative;
}

.container .notification {
  position: absolute;
  top: 0; left: 0;
  width: 100%;
}
</style>
