<template lang="pug">
div(v-if="liveShares.length")
  
  h3.title.is-3.mt-5
    slot
  
  ul.liveshare-list(
    class="level is-mobile mt-2"
  )
    li.liveshare-list-item(
      class="level-item mt-4"
      v-for="liveShare in liveShares"
      :key="liveShare.id"
    )
      granted-live-share-item(
        :liveShare="liveShare"
      )
</template>

<script>
import GrantedLiveShareItem from "@/components/nvr/GrantedLiveShareItem.vue";

export default {
  name: "LiveShareList",
  components: {
    GrantedLiveShareItem,
  },
  computed: {
    liveShares() {
      return this.$store.state.nvr.grantedLiveShares;
    },
  },
};
</script>


<style lang="scss" scoped>
.liveshare-list {
  width: 100%;
  align-items: flex-start;
}

.liveshare-list-item {
  flex: 50% 1 1;
  max-width: 50%;
}

</style>