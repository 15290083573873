<template lang="pug">

div
  div.level
    h2.title.is-4 Overview
    button.button.is-primary.is-inverted(
      @click="isEditting=!isEditting"
    )
      span Edit      

  div
    div(v-if="isEditting")
      div.field
        p.is-warning.notification(v-if="formError") {{ formError }}
      
      div.field
        label.label Name
        div.control
          input.input(
            :disabled="isSubmitting"
            placeholder="Play room"
            type="text" required
            v-model="name.value"
            @input="validateName"
          )
        p.is-danger.help(v-if="name.error") {{ name.error }}

      div.field
          label.label Stream URL
          div.control
            input.input(
              :disabled="isSubmitting"
              placeholder="rtsp://username:password@some-domain:554"
              type="text" required
              v-model="uri.value"
              @input="validateUri"
            )          
          p.is-danger.help(v-if="uri.error") {{ uri.error }}

      div.field
        div.control
          div.buttons
            button.button.is-primary(
              @click="submit"
              aria-label="save"  
              :disabled="!isFormValid"
              :class="{ 'is-loading': isSubmitting }"
            ) Save
            button.button(aria-label="cancel" @click="isEditting=false") Cancel
        
    div(v-else)
      table.table
        tr
          th Name
          td {{ pipeline.name }}
        tr 
          th Stream URI
          td {{ pipeline.source.baseUri }}
        tr
          th ID 
          td {{ pipeline.id }}
        tr
          th UUID 
          td {{ pipeline.uuid }}
        tr 
          th Sink UUID
          td {{ pipeline.sink.uuid }}

</template>

<script>
import { req } from "@/utils/validators";
import { NVRActionTypes } from "@/store/defs";
import { Pipeline } from "@/models/nvr";
import api from "@/api/nvr";


export default {
  name: "PipelineEditForm",
  props: {
    pipeline: Pipeline,
  },
  data() {
    return {
      isEditting: false,
      name: {
        value: '',
        error: '',
        dirty: false,
        valid: false
      },
      uri: {
        value: '',
        error: '',
        dirty: false,
        valid: false
      },
      formError: '',
      isSubmitting: false
    }
  },
  beforeMount() {
    this.uri.value = this.pipeline.source.baseUri;
    this.name.value = this.pipeline.name;
  },
  computed: {
    buttonText() {
      return this.isEditting ? 'Close' : 'Edit'
    },
    isFormValid() {
      return ((!this.name.dirty || this.name.valid) && (!this.uri.dirty || this.uri.valid));
    },
  },
  methods: {
    validateName() {
      this.name.dirty = true
      this.name.valid = req(this.name.value)
      this.name.error = this.name.valid ? '' : 'This field is required.'
    },
    validateUri() {
      this.uri.dirty = true
      const scheme = this.uri.value.split('://')[0]
      const schemeValid = !(['rtsp', 'http', 'https', 'rtmp'].indexOf(scheme) === -1)
      this.uri.valid = (schemeValid && this.uri.value.length >= 12)
      this.uri.error = this.uri.valid 
        ? '' : 'This field is required. It must be a valid URL.'
    },
    async isUriHealthy() {
      try {
        const result = await api.checkSource(this.uri.value);
        if (!result.is_valid) {
          this.uri.error = 'The given URL cannot be reached. Additional info: ' + result.error
          return false
        }
      } catch(e) {
        console.error(e)
        return false
      }
      return true
    },
    async submit() {
      if (!this.isFormValid) {
        return
      }
      this.isSubmitting = true
      this.formError = ''
      
      let isHealthy = true
      if (this.uri.dirty) {
        isHealthy = await this.isUriHealthy()
      } 
      if (isHealthy) {
        await this.updatePipeline()
        this.isEditting = false
      } else {
        this.formError = 'Stream URL is NOT reachable or correct.'
      }
      this.isSubmitting = false
    },
    async updatePipeline() {
      const data =  {
        name: this.name.value,
        source: {
          base_uri: this.uri.value,
        }
      }
      return await this.$store.dispatch(
        NVRActionTypes.UPDATE_PIPELINE,
        { id: this.pipeline.id, data: data }
      )
    },
  }
}
</script>