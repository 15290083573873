import api from "@/api/users";
import { User } from "@/models/users";
import { UsersActionTypes, UsersMutationTypes, UsersGetterTypes } from "@/store/defs";

const state = () => ({
	me: null,
});

const getters = {
	settings(state) {
		let _settings = {
			locale: process.env.VUE_APP_I18N_LOCALE,
		};
		if (state.me !== null) {
			_settings = state.me.settings;
		}
		return _settings;
	},
	locale: (_, getters) => getters.settings.locale,
	isStaff: (state) => (state.me) ? state.me.isStaff : false,
};

const mutations = {
	[UsersMutationTypes.SET_ME](state, me_data) {
		state.me = new User(me_data);
	},
};

const actions = {
	async [UsersActionTypes.RETRIEVE_ME]({ commit }) {
		const data = await api.retrieveMe();
		commit(UsersMutationTypes.SET_ME, data);
	},
	async [UsersActionTypes.UPDATE_ME]({ commit }, payload) {
		const data = await api.updateMe(payload.data);
		commit(UsersMutationTypes.SET_ME, data);
	},
};

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations,
};
