<template lang="pug">

div.modal(:class="{ 'is-active': isActive }")
  div.modal-background
  div.modal-card
    
    header.modal-card-head
      p.modal-card-title Connect video stream
      button.delete(aria-label="close" @click="$emit('close')")
    
    section.modal-card-body
      div
        div.field
          p.is-warning.notification(v-if="formError") {{ formError }}
        div.field
          label.label Name
          div.control
            input.input(
              :disabled="isSubmitting"
              placeholder="Play room"
              type="text" required
              v-model="name.value"
              @input="validateName"
            )
          p.is-danger.help(v-if="name.error") {{ name.error }}

        div.field
          label.label Stream URL
          div.control
            input.input(
              :disabled="isSubmitting"
              placeholder="rtsp://username:password@some-domain:554"
              type="text" required
              v-model="uri.value"
              @input="validateUri"
            )          
          p.is-danger.help(v-if="uri.error") {{ uri.error }}
      
    footer.modal-card-foot
      button.button.is-primary(
        @click="submit"
        aria-label="submit" 
        :disabled="!isFormValid"
        :class="{ 'is-loading': isSubmitting }"
      ) Submit
      button.button(aria-label="close" @click="$emit('close')") Cancel
</template>

<script>
// import useVuelidate from '@vuelidate/core'
// import { required, email } from '@vuelidate/validators'
import { req } from "@/utils/validators";
import api from "@/api/nvr";
import { NVRActionTypes } from "@/store/defs";

export default {
  name: "PipelineCreateForm",
  props: {
    isActive: Boolean,
  },
  emits: ['close'],
  // setup () {
  //   return { v$: useVuelidate() }
  // },
  data() {
    return {
      name: {
        value: '',
        error: '',
        dirty: false,
        valid: false
      },
      uri: {
        value: '',
        error: '',
        dirty: false,
        valid: false
      },
      doesLive: true,
      doesRecording: false,
      formError: '',
      isSubmitting: false
    }
  },
  computed: {
    isFormValid() {
      return (this.name.valid && this.uri.valid);
    },
  },
  methods: {
    validateName() {
      this.name.dirty = true
      this.name.valid = req(this.name.value)
      this.name.error = this.name.valid ? '' : 'This field is required.'
    },
    validateUri() {
      this.uri.dirty = true
      const scheme = this.uri.value.split('://')[0]
      const schemeValid = !(['rtsp', 'http', 'https', 'rtmp'].indexOf(scheme) === -1)
      this.uri.valid = (schemeValid && this.uri.value.length >= 12)
      this.uri.error = this.uri.valid 
        ? '' : 'This field is required. It must be a valid URL.'
    },
    async isUriHealthy() {
      try {
        const result = await api.checkSource(this.uri.value);
        if (!result.is_valid) {
          this.uri.error = 'The given URL cannot be reached. Additional info: ' + result.error
          return false
        }
      } catch(e) {
        console.error(e)
        return false
      }
      return true
    },
    async createPipeline() {
      try {
        await this.$store.dispatch(NVRActionTypes.CREATE_PIPELINE, {
          data: {
            name: this.name.value,
            source: {
              base_uri: this.uri.value,
            },
            does_recording: this.doesRecording,
            does_live: this.doesLive,
          }
        })
      } catch(e) {
        console.error(e)
        this.formError = `Error ${e.status} ${e.statusText}`
        if (e.data.source) {
          this.formError += " / URI is not unique. It has been used by another user."
        }
        if (e.data.name) {
          this.formError += " / Name is not unique. It has been used by another user."
        }
      }
    },
    async submit() {
      if (!this.isFormValid) {
        return
      }
      this.isSubmitting = true
      this.formError = ''
      const isHealthy = await this.isUriHealthy()
      if (isHealthy) {
        await this.createPipeline()
        this.$emit('close')
      } else {
        this.formError = 'Stream URL is NOT reachable or correct.'
      }
      this.isSubmitting = false
    }
  }
}

</script>

