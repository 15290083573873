/* eslint-disable no-unused-vars */

import api from "@/api/core";
import { CoreActionTypes, CoreMutationTypes } from "@/store/defs";

const defaultFeedback = { text:'', type: 'info'}

const state = () => ({
  csrfToken: "",
  hasAuthed: null,
  feedback: defaultFeedback,
});

const getters = {
};

const mutations = {
  [CoreMutationTypes.SET_CSRF_TOKEN](state, token) {
    state.csrfToken = token;
  },
  [CoreMutationTypes.SET_HAS_AUTHED](state, hasAuthed) {
    state.hasAuthed = hasAuthed;
  },
  [CoreMutationTypes.SET_APP_FEEDBACK](state, feedback) {
    Object.assign(state.feedback, feedback)
  },
  [CoreMutationTypes.RESET_APP_FEEDBACK](state) {
    Object.assign(state.feedback, defaultFeedback);
    state.feedback.text = ''
  },
};

const actions = {
  async [CoreActionTypes.RETRIEVE_CSRF_TOKEN]({ commit }) {
    const result = await api.retrieveCsrfToken();
    commit(CoreMutationTypes.SET_CSRF_TOKEN, result.csrf_token);
  },
  async [CoreActionTypes.RETRIEVE_SESSION]({ commit }) {
    try {
      const result = await api.retrieveSession();
      // console.info(result)
      commit(CoreMutationTypes.SET_HAS_AUTHED, result.is_authenticated);
    } catch (err) {
      console.error(err)
      commit(CoreMutationTypes.SET_HAS_AUTHED, false);
    }
  },
  async [CoreActionTypes.LOG_IN]({ commit }, payload) {
    await api.login(payload);
    commit(CoreMutationTypes.SET_HAS_AUTHED, true);
  },

  async [CoreActionTypes.LOG_OUT]({ commit }) {
    await api.logout();
    commit(CoreMutationTypes.SET_HAS_AUTHED, false);
  },
};

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
};
