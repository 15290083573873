<template lang="pug">
div
  h2.title.is-4 Live Shares 
  
  div.field
    div.control
      button.button.is-small.is-primary(@click="showCreateModal=true")
        i.icon
          span.fas.fa-share
        span Invite
  
  div.field
    live-share-create-form(
      v-if="showCreateModal"
      :isActive="showCreateModal"
      :pipeline="pipeline"
      @close="showCreateModal=false"
    )
    
    ul
      li.mt-4(
        v-for="(liveShare, idx) in liveShares" 
        :key="liveShare.id"
      )
        live-share-item(
          :liveShare="liveShare" 
          :idx="idx"
          @delete="chosenLiveShare=liveShare"
        ) 
    
  //- Modals
  confirmation-modal(
    v-if="chosenLiveShare"
    :isActive="showDeleteModal" 
    @confirm="deleteLiveShare" 
    @close="chosenLiveShare=null"
  ) 
    span Should I disable access for 
    strong "{{ chosenLiveShare.ownerFullName }}"
    span  to the 
    strong "{{ chosenLiveShare.name }}" 
    span  stream?

</template>

<script>
import { NVRActionTypes } from "@/store/defs";
import LiveShareItem from "@/components/nvr/LiveShareItem.vue";
import LiveShareCreateForm from "@/components/nvr/LiveShareCreateForm"
import { Pipeline } from "@/models/nvr";

export default {
  name: "LiveShareList",
  components: {
    LiveShareItem,
    LiveShareCreateForm,
  },
  props: {
    pipeline: Pipeline
  },
  data() {
    return {
      chosenLiveShare: null,
      showCreateModal: false,
    }
  },
  computed: {
    showDeleteModal() {
      return this.chosenLiveShare !== null
    },
    liveShares() {
      return this.$store.state.nvr.liveShares;
    },
  },
  methods: {
    dispalyDeleteModal() {
      show
    },
    async deleteLiveShare() {
      if (!this.chosenLiveShare) {
        return
      }
      await this.$store.dispatch(NVRActionTypes.DESTROY_LIVE_SHARE, {
        id: this.chosenLiveShare.id
      })
      this.chosenLiveShare = null
    }
  }
};
</script>
