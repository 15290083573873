import { createI18n } from "vue-i18n";

const messages = {
  "en": {
    "message": "hello i18n !!",
    "pages": {
      "settings": "Settings",
      "cameras": "Cameras",
      "watch": "Watching",
      "login": "Log In"
    },
    "ui": {
      "back": "Back",
      "settings": "Sett.",
      "naumcho": "Naumcho",
      "logout": "Log out",
      "creator": "by Georgy St. Geogriev",
      "logged": "Logged in as",
      "noview": "Cannot watch right now.",
      "viewperiods": "Can view between"
    },
    "settings": {
      "locale": "Language"
    },
    "cameras": {
      "mine": "Mine",
      "shared": "Shared"
    },
    "forms": {
      "email": "Email",
      "name": "Name",
      "username": "Username",
      "password": "Password",
      "submit": "Submit",
      "resetPassword": "Reset password",
    }
  },
  "bg": { 
    "message": "здрасти i18n !!",
    "pages": {
      "settings": "Настройки",
      "cameras": "Камери",
      "watch": "Гледаш",
      "login": "Влез"
    },
    "ui": {
      "back": "Назад",
      "settings": "Настр.",
      "naumcho": "Наумчо",
      "logout": "Излез",
      "creator": "от Георги Ст. Георгиев",
      "logged": "Влязал като",
      "noview": "Не може да гледате сега.",
      "viewperiods": "Може да гледате между"
    },
    "settings": {
      "locale": "Eзик"
    },
    "cameras": {
      "mine": "Мои",
      "shared": "Споделени"
    },
    "forms": {
      "email": "Ел.поща",
      "name": "Име",
      "username": "Потребител",
      "password": "Парола",
      "submit": "Запиши",
      "resetPassword": "Смени паролата",
    } 
  }
}

export default createI18n({
  legacy: true,
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: messages,
  globalInjection: true,
});
