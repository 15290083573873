<template lang="pug">
div.card.liveshare-item(:key="liveShare.updatedAt")
  div.card-body(:class="{'has-background-grey-lighter': !canView }")
    div(@click="watch")
      div.card-image(ref="image")
        figure.image.is-4by3
          img(
            :src="thumbnailSrc" ref="image" alt="thumbnail" type="media/jpeg"
            :key="thumbnailKey"
            crossorigin="anonymous"
          )
        div(class="card-mask" v-if="!liveShare.canView")
          icon.card-mask-icon.has-text-danger-dark(name="lock")
          h4.title.is-4.has-text-centered.mt-3.has-text-danger-dark
            | Cannot watch right now.
    div.card-content
      p.has-text-centered
        | {{ liveShare.name }} 
      div(v-if="!liveShare.isEnabled")
        p.has-text-centered
          strong.has-text-danger disabled
      div.mt-4(v-else)
        div.view-periods(v-if="viewPeriodsAsDayTimes.length > 0")
          p.label View periods:
          ol.title.is-6
            li.mt-2(v-for="period in viewPeriodsAsDayTimes")
              | {{ period.start }} - {{ period.end }}
        

</template>

<script>
import { LiveShare } from "@/models/nvr";
import { CoreMutationTypes } from "@/store/defs";
import { isElementVisible } from "@/utils"

const defaultSrc = `${process.env.BASE_URL}images/default_video_thumbnail.png`


export default {
  name: "LiveShareListItem",
  props: {
    liveShare: {
      type: LiveShare,
      required: true,
    },
  },
  data() {
    return {
      thumbnailKey: 0,
      thumbnailInterval: null
    }
  },
  computed: {
    viewPeriodsAsDayTimes() {
      return this.liveShare.viewPeriods.asReadableDayTimes()
    },
    thumbnailSrc() {
      return this.canView 
      ? this.liveShare.sink.getResourcePath('frame') + `?key=${this.thumbnailKey}`
      : defaultSrc;
    },
    canView() {
      return this.liveShare.canView;
    },
    state() {
      return this.liveShare.isEnabled ? 'enabled' : 'disabled';
    },
  },
  beforeMount() {
    this.thumbnailInterval = setInterval(() => {
      if (isElementVisible(this.$refs.image)) {
        this.thumbnailKey += 1
      }
    }, 10000)
  },
  beforeUnmount() {
    clearInterval(this.thumbnailInterval)
  },
  methods: {
    watch() {
      if (!this.liveShare.canView) {
        this.$store.commit(CoreMutationTypes.SET_APP_FEEDBACK, {
          text: 'Live stream is disabled.',
          type: 'warning',
        })
      } else {
        this.$router.push({
          name: "watch",
          params: {
            id: this.liveShare.id,
            type: 'liveShare'
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.liveshare-item {
  width: 100%;
}


h3.title {
  margin-bottom: 0;
}

.card-content {
  background: white;
}

.view-periods ol {
  padding-left: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  li {
    flex-basis: 50%;
  }
}

.view-periods p.label {
  text-align: center;
}

.card-image {
  position: relative;
}

.card-footer {
  width: 100%;
}

.view-period {
  width: 50%;
}

.card-mask {
  // background: gray;
  opacity: 0.8;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.card-mask-icon {
  width: 100%;
  margin-top: 30%;
  text-align: center;
}
</style>
