<template lang="pug">
div.card.pipeline-item
  div.card-body(:class="{'has-background-grey-lighter': !doesLive }")
    div(@click="watch")
      div.card-image(ref="image")
        figure.image.is-4by3
          img(
            :src="thumbnailSrc" ref="image" alt="thumbnail" type="media/jpeg"
            :key="thumbnailKey"
            crossorigin="anonymous"
          )
    div.card-content.has-text-centered
      p.level-item.has-text-centered {{ pipeline.name }}
      strong.has-text-danger(v-if="!doesLive") Live Disabled
  footer.card-footer
    a.card-footer-item(@click="watch")
      span.icon.has-text-primary
        i.fas.fa-play
    router-link.card-footer-item(
      v-if="isStaff"
      :to="recordLink"
    )
      span.icon.has-text-primary
        i.fas.fa-image
    //- router-link.card-footer-item(:to="optionLink")
    //-   span.icon.has-text-primary
    //-     i.fas.fa-ellipsis-v
    a.card-footer-item.dropdown(
      @click="showOptions=!showOptions"
      :class="{'is-active': showOptions }"
    )
      .dropdown-trigger
        span.icon.has-text-primary
          i.fas.fa-ellipsis-v 
      .dropdown-menu
        .dropdown-content
          router-link.card-footer-item.dropdown-item.has-text-primary(
            :to="optionLink"
          )
            strong Settings
          hr.dropdown-divider
          a.dropdown-item.has-text-danger(@click="showDeleteConfirmation=true")
            strong Delete
    
    div.modal(:class="{ 'is-active': showDeleteConfirmation }")
      div.modal-background
      div.modal-card
        header.modal-card-head
          p.modal-card-title Should I delete "{{ pipeline.name }}"?
        div.modal-card-foot
          button.button.is-danger(
            aria-label="delete pipeline" 
            @click="deletePipeline"
          ) Delete
          button.button(
            aria-label="close" 
            @click="showDeleteConfirmation=false"
          ) Cancel 

</template>

<script>
import { NVRActionTypes } from "@/store/defs";
import { Pipeline } from "@/models/nvr";
import { CoreMutationTypes } from "@/store/defs";
import { isElementVisible } from "@/utils"
const defaultSrc = `${process.env.BASE_URL}images/default_video_thumbnail.png`


export default {
  name: "PipelineListItem",
  props: {
    pipeline: {
      type: Pipeline,
      required: true,
    },
  },
  data() {
    return {
      showOptions: false,
      showDeleteConfirmation: false,
      thumbnailKey: 0,
      thumbnailInterval: null,
    };
  },
  computed: {
    isStaff() {
      return this.$store.getters.isStaff
    },
    recordLink() {
      return { 
        name: 'records', 
        query: { pipeline: this.pipeline.id }
      }
    },
    optionLink() {
      return { name:'pipeline', params: { id: this.pipeline.id }}
    },
    thumbnailSrc() {
      return this.pipeline.doesLive
      ? this.pipeline.sink.getResourcePath('frame') + `?key=${this.thumbnailKey}`
      : defaultSrc;
    },
    doesLive() {
      return this.pipeline.doesLive;
    },
  },
  mounted() {
    if (this.thumbnailInterval) {
      return
    }
    clearInterval(this.thumbnailInterval)
    this.thumbnailInterval = setInterval(() => {
      if (isElementVisible(this.$refs.image)) {
        this.thumbnailKey += 1
      }
    }, 10000)
  },
  beforeUnmount() {
    this.thumbnailKey = 0
    clearInterval(this.thumbnailInterval)
  },
  methods: {
    async deletePipeline() {
      try {
        this.$router.push({ name: 'home' });
        await this.$store.dispatch(NVRActionTypes.DESTROY_PIPELINE, {
          id: this.pipeline.id
        })

      } catch(e) {
        console.error(e)
      }
    },
    watch() {
      if (!this.pipeline.doesLive) {
        this.$store.commit(CoreMutationTypes.SET_APP_FEEDBACK, {
          text: 'Live stream is disabled.',
          type: 'warning',
        })
      } else {
        this.$router.push({
          name: "watch",
          params: {
            id: this.pipeline.id,
            type: 'pipeline', 
          },
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>

.pipeline-item {
  width: 100%;
}

</style>
