import { createApp } from "vue";
import App from "./App.vue";
import MainAppLayout from "@/views/layouts/MainAppLayout.vue";

import router from "@/router";
import store from "@/store";
import i18n from "@/i18n";
import VCalendar from 'v-calendar';
import Model from "@/models";

import "@/styles/main.scss";
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from "@fortawesome/vue-fontawesome";
import { Icon, ConfirmationModal, CardModal } from "@/components/elements"
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

Model.$store = store;

String.prototype.format = function() {
  let a = this;
  for (let k in arguments) {
    a = a.replace("{" + k + "}", arguments[k])
  }
  return a
}

const app = createApp(App).use(i18n).use(store).use(router).use(VCalendar, {});
app
  .component("MainAppLayout", MainAppLayout)
  .component("Icon", Icon)
  .component("CardModal", CardModal)
  .component("ConfirmationModal", ConfirmationModal);
  // .component("FontAwesomeIcon", FontAwesomeIcon)
  // .component("FontAwesomeLayers", FontAwesomeLayers)
  // .component("FontAwesomeLayersText", FontAwesomeLayersText);

library.add(fas);
dom.watch();

const vm = app.mount("#app");
store.$app = vm;

