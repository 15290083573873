<template lang="pug">
div.level
  div.level-left
    div.level-item 

      time-field(
        :time="_period.start" @update:time="updateStart"
      )
      
      span &emsp;
      
      time-field(
        :time="_period.end" @update:time="updateEnd"
      )
  div.level-right.ml-4
    div.level-item
      slot

</template>


<script>
import TimeField from "@/components/fields/TimeField"

export default {
  name: "PeriodField",
  components: {
    TimeField,
  },
  props: {
    period: Object,
  },
  computed: {
    _period: {
      get() {
        return _.cloneDeep(this.period)
      },
      set(val) {
        const period = this._period
        Object.assign(period, val)
        this.$emit('update:period', period)
      }
    }
  },
  emits: ['update:period'],
  methods: {
    updateStart(val) {
      this._period = { start: val }
    },
    updateEnd(val) {
      this._period = { end: val }
    },
  }
}
</script>

