<template lang="pug">

main-app-layout(:hasBackButton="true")
  
  template(#title)
    h1.title.is-3 Watch {{ name }}
  
  section.section.is-fullwidth      
    div.columns.is-fullwidth
      div.column
        div.player
          div.field
            div(v-if="shouldPlayHls")
              player-hls.mb-4.mt-4(
                :uri="uri" 
                :hasControls="isPipeline" 
                :shouldPlay="true"
                :isMute="isMute"
              )
            div(v-else)
              player-jpeg(:uri="uri")
          
          div.field(v-if="isHlsSupported")
            div.control.has-text-centered
              p
                input.switch(
                  @click="isHls=!isHls" 
                  type="checkbox" id="switchName" 
                  :checked="isHls"
                )
                label(for="switchName") HLS
              p
                i (Switch off for older devices)
        
</template>

<script>
import Hls from "hls.js"
import PlayerHls from "@/components/nvr/PlayerHls.vue";
import PlayerJpeg from "@/components/nvr/PlayerJpeg.vue";

export default {
  name: "LiveMonitorDetailView",
  components: {
    PlayerHls,
    PlayerJpeg,
  },
  props: {
    id: String,
    type: String,
  },
  data() {
    return {
      isHls: true,
      // model: null,
      interval: null,
    }
  },
  watch: {
    model(newValue, oldValue) {
      if(!newValue) {
        this.$router.push({ name: 'home' });
      }
    }
  },
  computed: {
    isHlsSupported() {
      const video = document.createElement('video')
      return Hls.isSupported() || video.canPlayType('application/vnd.apple.mpegurl')
    },
    shouldPlayHls()  {
      return (this.isHlsSupported && this.isHls)
    },
    modelId() {
      return Number(this.id);
    },
    name() {
      return this.model.name;      
    },
    uri() {
      return this.isHls
        ? this.model.sink.getResourcePath()
        : this.model.sink.getResourcePath('frame');
    },
    model() {
      return this.isPipeline
      ? this.$store.getters.pipeline(this.modelId)
      : this.$store.getters.grantedLiveShare(this.modelId);
    },
    isMute() {
      return this.isPipeline
      ? false
      : this.model.isMute
    },
    label() {
      return this.isHls ? 'HLS' : 'MJpeg'; 
    },
    isPipeline() {
      return (this.type.indexOf('pipeline') !== -1)
    },
  },
  beforeMount() {
    this.isHls = this.isHlsSupported

    if (!this.isPipeline) {
      this.interval = setInterval(() => {
        const liveShare = this.model;

        if (!liveShare.canView) {
          this.$router.push({ name: 'home' });
        }
      }, 10000);
    }
  },
  beforeUnmount() {
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = null;
    }
  },
  methods: {
    toggleHls() {
      this.isHls = !this.isHls;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~/node_modules/bulma/sass/utilities/_all.sass";


.player {
  @include mobile {
    .box {
      padding-left: 0;
      padding-right: 0
    }
  }
}



</style>
