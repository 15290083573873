import request from "../utils/request";
import endpoints from "@/api/endpoints";

export async function retrieveMe() {
  const res = await request.get(endpoints.USERS_ME);
  return res.data
}

export async function updateMe(data) {
  const res = await request.patch(endpoints.USERS_ME, data);
  return res.data
}

export async function setPassword(data) {
  const res = await request.post(endpoints.USERS_SET_PASSWORD, data);
  return res.data
}

export async function resetPassword(data) {
  const res =  await request.post(endpoints.USERS_RESET_PASSWORD, data);
  return res.data
}

export async function confirmResetPassword(data) {
  const res = await request.post(endpoints.USERS_CONFIRM_RESET_PASSWORD, data);
  return res.data
}

export default {
  retrieveMe,
  updateMe,
  setPassword,
  resetPassword,
  confirmResetPassword,
};
