<template lang="pug">
main-app-layout(:hasBackButton="true")
  
  template(#title)
    h1.title.is-3 Records
    h2.subtitle Showing: {{ first }} - {{ last }} / {{ totalCount }}
  
  section.section
    div.field
      record-player-modal(
        :isActive="showPlayerModal"
        :record="playingRecord"
        @stop="stopRecord"
        @next="playNextRecord"
        @previous="playPreviousRecord"
      )

    div.field
      record-nav(
        :first="filters.first"
        :last="filters.last"
        :totalCount="totalCount"
        @filter="showFilterForm=!showFilterForm"
        @edit="showEditForm=!showEditForm"
        @next="showNext"
        @previous="showPrevious"
      )

    div.field.box(v-if="showFilterForm")
      record-filter-form(
        v-model:filters="filters"
        @filter="onFilterChange"
      )
    
    div.field
      record-list(
        @play="playRecord" 
        @check="checkedRecords=$event" 
        :showEditForm="showEditForm"
        :offset="filters.offset"
        :limit="filters.limit"
        :totalCount="totalCount"
        :records="records"
      )
    
    div.field
      record-nav(
        :first="filters.first"
        :last="filters.last"
        :totalCount="totalCount"
        :showFormButtons="false"
        @filter="showFilterForm=!showFilterForm"
        @edit="showEditForm=!showEditForm"
        @next="showNext"
        @previous="showPrevious"
      )

</template>

<script>
import { nextTick } from 'vue'
import _keyBy from "lodash/keyBy";
import { NVRActionTypes, NVRMutationTypes } from "@/store/defs";
import RecordList from "@/components/nvr/RecordList.vue";
import RecordFilterForm from "@/components/nvr/RecordFilterForm";
import RecordPlayerModal from "@/components/nvr/RecordPlayerModal";
import RecordNav from "@/components/nvr/RecordNav";
import { insertLocationQueryParam } from "@/utils";


export default {
  name: "Records",
  components: {
    RecordList,
    RecordNav,
    RecordFilterForm,
    RecordPlayerModal,
  },
  data() {
    return {
      showFilterForm: false,
      showEditForm: false,
      showPlayerModal: false,
      playingRecord: null,
      filters: {
        ordering: '-started_at',
        limit: 50,
        offset: 0,
        search: null,
        from: null, // 1615800367296/1000,
        to: null,
        pipeline: null,
      },
    }
  },
  computed: {
    records() {
      return this.$store.getters.records;
    },
    totalCount() {
      return this.$store.state.nvr.recordsTotalCount;
    },
    first() {
      return Math.max(this.filters.offset, 0)
    },
    last() {
      return Math.min(this.filters.offset + this.filters.limit, this.totalCount)
    },
    locKey() {
      return (process.env.NODE_ENV === 'production') ? 'search' : 'hash';
    }
  },
  async beforeMount() {
    Object.assign(this.filters, this.$route.query)
    await this.filterRecords(); 
  },
  beforeUnmount() {
    this.$store.commit(NVRMutationTypes.SET_RECORDS, []);
  },
  methods: {
    playNextRecord() {
      const idx = this.records.findIndex(r => r.id === this.playingRecord.id);
      if (idx >= this.records.length) {
        return
      }
      this.playingRecord = null
      nextTick(() => {
        this.playingRecord = this.records[idx + 1];
      });
    },

    playPreviousRecord() {
      const idx = this.records.findIndex(r => r.id === this.playingRecord.id);
      if (idx <= 0) {
        return
      }
      this.playingRecord = null
      nextTick(() => {
        this.playingRecord = this.records[idx - 1];
      });
    },
    
    async onFilterChange() {
      await this.filterRecords();
    },

    async showNext() { 
      if (this.filters.offset + this.filters.limit >= this.totalCount) {
        return
      }
      this.filters.offset = this.filters.offset + this.filters.limit;
      await this.filterRecords()
    },

    async showPrevious(range) {
      if (this.filters.offset <= 0) {
        return
      }
      this.filters.offset = this.filters.offset - this.filters.limit;
      await this.filterRecords()
    },    

    async filterRecords() {
      const params = {};
      Object.assign(params, this.filters);
      if (params.from) {
        params.from = params.from.getTime() / 1000;
      }      
      if (params.to) {
        params.to = params.to.getTime() / 1000;
      }
      await this.$store.dispatch(
        NVRActionTypes.LIST_RECORDS, 
        { params: params }
      );
      // ToDo: fix when using WebHistory with document.location['search']
      // for (const key in this.filters) {
      //   if (this.filters[key]) {          
      //     insertLocationQueryParam(key, this.filters[key], this.locKey)
      //   }
      // }
    },

    playRecord(record) {
      this.playingRecord = record
      this.showPlayerModal = true
    },

    stopRecord() {
      this.playingRecord = null
      this.showPlayerModal = false
    }
  }
}
</script>

<style lang="scss" scoped>

.records-nav {
  // background: red;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  p {
    margin-right: 1rem;
  }
}

</style>