<template lang="pug">

// Search

div.field.is-horizontal
  div.field-label.is-normal
    label.label Search
  
  div.field-body
    div.field.has-addons
      div.control.has-icons-left
        input.input(
          type="text" placeholder="search..." 
          v-model="search"
          @keyup.enter="doFilter"
        )
        span.icon.is-left
          i.fas.fa-search
      div.control 
        button.button.is-primary(@click="doFilter") OK

// Offset and Limit

div.field.is-horizontal
  div.field-label.is-normal
    label.label Number of items
  div.field-body
    div.field.has-addons
      div.control
        input.input(
          type="number"
          v-model.number="limit"
          @keyup.enter="doFilter"
        )
      div.control
        button.button.is-primary(@click="doFilter") Set

//-   div.field-body
//-     div.field
//-       div.control
//-         input.input(
//-           type="number"
//-           v-model.number="offset"
//-           @keyup.enter="doFilter"
//-         )

// From / To

div.field.is-horizontal
  div.field-label.is-normal
    label.label From / To

  div.field-body
    div.field
      div.control
        v-date-picker(
          v-model="from" 
          @dayclick="doFilter" 
         
        )
          template(v-slot="{ inputValue, inputEvents }")
            div.field.has-addons
              div.control.is-expanded
                input.input(
                  :value="inputValue"
                  v-on="inputEvents"
                )
              div.control
                button.button.is-primary(
                  type="button"
                  class="text-white font-bold py-2 px-4 rounded-r user-select-none focus:outline-none"
                  :disabled="!from"
                  @click="from = null"
                ) Clear
  
    div.field
      div.control
        v-date-picker(
          v-model="to" 
          @dayclick="doFilter" 
          :input-debounce="1000"
        )
          template(v-slot="{ inputValue, inputEvents }")
            div.field.has-addons
              div.control.is-expanded
                input.input(
                  :value="inputValue"
                  v-on="inputEvents"
                )
              div.control
                button.button.is-primary(
                  type="button"
                  class="text-white font-bold py-2 px-4 rounded-r user-select-none focus:outline-none"
                  :disabled="!to"
                  @click="to = null"
                ) Clear

</template>

<script>

export default {
  name: "RecordFilterForm",
  components: {},
  props: ['filters'],
  emits: ['update:filters'],
  data() {
    return {}
  },
  computed: {
    search: {
      get() { return this.filters.search },
      set(value) { this.update({ search: value }) }
    },
    from: {
      get() { return this.filters.from },
      set(value) { this.update({ from: value }) }
    },
    to: {
      get() { return this.filters.to },
      set(value) { this.update({ to: value }) }
    },
    limit: {
      get() { return this.filters.limit },
      set(value) { this.update({ limit: value }) }
    },
    offset: {
      get() { return this.filters.offset },
      set(value) { this.update({ offset: value }) }
    },
  },
  methods: {
    doFilter() {
      this.$emit('filter')
    },
    update(value) {
      const filters = Object.assign({}, this.filters, value)  
      this.$emit('update:filters', filters);
    },
  }
}

</script>