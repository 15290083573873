<template lang="pug">
main-app-layout(:key="key" :hasBackButton="true")

  template(v-slot:title)
    h1.title.is-3 Stream: {{ pipeline.name }}

  section.section.is-fullwidth
    pipeline-edit-form.section-item(:pipeline="pipeline")
    pipeline-controls.section-item(
      :pipeline="pipeline"
      @toggleLive="toggleLive"
      @toggleRecording="toggleRecording"
      @delete="showDeleteModal=true"  
    )

    live-share-list.section-item(:pipeline="pipeline")
    
    //- Modals
    confirmation-modal(
      :isActive="showDeleteModal" 
      @confirm="deletePipeline" 
      @close="showDeleteModal=false"
    ) 
      span Should I delete "{{ pipeline.name }}"?
   

    //- div.box
    //-   p Apparently we had reached a great height in the atmosphere, for the sky was a dead black, 
    //-     | and the stars had ceased to twinkle. By the same illusion which lifts the horizon 
    //-     | of the sea to the level of the spectator on a hillside, the sable cloud beneath was dished out, and the car
    //-     |  seemed to float in the middle of an immense dark sphere, whose upper half was strewn with silver. Looking 
    //-     | down into the dark gulf below, I could see a ruddy light streaming through a rift in the clouds. 
</template>

<script>
import { NVRActionTypes } from "@/store/defs";
import PipelineEditForm from "@/components/nvr/PipelineEditForm";
import PipelineControls from "@/components/nvr/PipelineControls";
import LiveShareList from "@/components/nvr/LiveShareList";

export default {
  name: "PipelineDetailView",
  components: {
    PipelineEditForm,
    PipelineControls,
    LiveShareList
  },
  props: {
    id: String,
  },
  data() {
    return {
      key: 0,
      showDeleteModal: false
    }
  },
  watch: {
    pipeline: {
      handler() { this.key += 1 }
    }
  },
  computed: {
    pipelineId() {
      return Number(this.id)
    },
    pipeline() {
      return this.$store.getters.pipeline(this.pipelineId);
    },
  },
  methods: {
    toggleLive() {
      this.$store.dispatch(NVRActionTypes.UPDATE_PIPELINE, {
        id: this.pipeline.id,
        data: { does_live: !this.pipeline.doesLive },
      });
    },
    toggleRecording() {
      this.$store.dispatch(NVRActionTypes.UPDATE_PIPELINE, {
        id: this.pipeline.id,
        data: { does_recording: !this.pipeline.doesRecording },
      });
    },
    async deletePipeline() {
      try {
        this.$router.push({ name: 'home' });
        await this.$store.dispatch(NVRActionTypes.DESTROY_PIPELINE, {
          id: this.pipeline.id
        })

      } catch(e) {
        console.error(e)
      }
    },
    
  }
}
</script>

<style lang="scss" scoped>
@import "~/node_modules/bulma/sass/utilities/_all.sass";

.section-item {
  margin-top: 1.5rem;
  background: white;
  padding: 0.5rem 0.25rem;
  overflow: scroll;
}

</style>