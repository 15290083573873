<template lang="pug">
main-app-layout(navLeftAction="hide")
  template(#title)
    h1.title.is-3 Sign In 
  
  section.section
    p.notification.is-danger(v-if="error") {{ error }}
    div.columns.is-desktop
      div.column.is-offset-one-quarter.is-half
        div.field
          div.control
            label(for="username", class="label") Username
            input(
              id="username"
              class="input"
              placeholder="Username"
              autofocus
              type="text"
              name="username"
              required
              v-model.trim="username"
            )
        div.field
          div.control
            label(for="password", class="label") Password
            input(
              id="password"
              class="input"
              placeholder="Password"
              type="password"
              name="password"
              required
              v-model.trim="password"
            )
        div.field
          div.control.buttons
            button.button.is-primary(@click="submit") 
              | OK
            button.button.is-link.is-light(@click="resetPassword") 
              | Reset password

</template>

<script>
import { CoreActionTypes } from "@/store/defs";

export default {
  name: "LogInView",
  props: {
    msg: String
  },
  data() {
    return {
      username: "",
      password: "",
      error: null
    };
  },
  methods: {
    async submit() {
      try {
        await this.$store.dispatch(CoreActionTypes.LOG_IN, {
          username: this.username,
          password: this.password
        });
      } catch (error) {
        this.error = "Login unsuccessful. \n" + String(error);
      }
      window.location.reload();
    },
    resetPassword() {
      console.info('click')
      this.$router.push({ name: 'passwordReset' });
    },

  }
};
</script>