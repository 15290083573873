import request from "../utils/request";
import endpoints from "@/api/endpoints";

export async function login(data) {
  const res = await request.post(endpoints.CORE_LOG_IN, data);
  return res.data
}

export async function logout() {
  const res = await request.get(endpoints.CORE_LOG_OUT);
  return res.data
}

export async function retrieveCsrfToken() {
  const res = await request.get(endpoints.CORE_CSRF);
  return res.data
}

export async function retrieveSession() {
  // console.info('retrieve session')
  const res = await request.get(endpoints.CORE_SESSION);
  // console.info(res)
  return res.data
}

export default {
  login,
  logout,
  retrieveCsrfToken,
  retrieveSession,
};
