<template lang="pug">
div.app-outer(ref="baseView") 
  router-view(v-if="canRender" ref="routerView")
  div.pageloader(:class="{ 'is-active': !canRender }")
    span.title Loading ...
</template>

<script>
import { CoreActionTypes, NVRActionTypes, UsersActionTypes, UsersGetterTypes } from '@/store/defs';

export default {
  name: "App",
  data() {
    return {
      canRender: false
    };
  },
  computed: {
    hasAuthed() {
      return this.$store.state.core.hasAuthed;
    },
    locale() {
      return this.$store.getters.locale;
    }
  },
  async mounted() {
    if (!this.hasAuthed) {
      await this.$store.dispatch(CoreActionTypes.RETRIEVE_SESSION)
    }
    if (this.hasAuthed) {
      await this.$store.dispatch(UsersActionTypes.RETRIEVE_ME);
      this.$i18n.locale = this.locale;
      await this.$store.dispatch(CoreActionTypes.RETRIEVE_CSRF_TOKEN);
      await this.$store.dispatch(NVRActionTypes.LIST_PIPELINES);
      await this.$store.dispatch(NVRActionTypes.LIST_GRANTED_LIVE_SHARES);
      await this.$store.dispatch(NVRActionTypes.LIST_LIVE_SHARES);
    } 
    console.info('Finished loading.')    
    this.canRender = true
  },
  methods: {
    // adjustHeight() {
    //   const clientHeight = document.getElementsByTagName('html')[0].clientHeight;
    //   const mainEl = document.getElementsByTagName('main')[0]
    //   console.info(clientHeight)
    //   mainEl.style.minHeight = String(clientHeight - 200) + 'px';
      
    // }
  }
};
</script>

<style lang="scss">
html, body {
  height: 100%;
}

.app-wrapper {
  position: relative;
  min-height: 100vh;
}

.app-wrapper main {
  padding-bottom: 100px;
}

footer.footer {
  padding: 1rem;
  position: absolute;
  bottom: 0;
  width: 100%;
  max-height: 100px;     
}
</style>