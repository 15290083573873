const eventNames = ["data", "error"];

export class WSAdapter {
  hooks = {
    data: [],
    error: [],
  };

  connection = null;

  get state() {
    return this.connection ? this.connection.readyState : WebSocket.CLOSED;
  }

  get isConnected() {
    const o = WebSocket.OPEN;
    const c = WebSocket.CONNECTING;
    return this.connection !== null && (this.state === o || this.state === c);
  }

  connect() {
    console.log("WS connecting.");
    if (this.isConnected) {
      console.info('WS connection is already opened or connecting.')
      return;
    }
    const connection = new WebSocket(
      `${process.env.VUE_APP_WS_BACKEND}/events/`, [] 
    );
    connection.onmessage = this.onMessage.bind(this);
    connection.onopen = this.onOpen.bind(this);
    connection.onclose = this.onClose.bind(this);
    connection.onerror = this.onError.bind(this);
    this.connection = connection;
  }

  disconnect() {
    if (!this.isConnected) {
      return;
    }

    if (this.connection) {
      this.connection.close();
    }
  }

  on(eventName, hook) {
    if (eventNames.indexOf(eventName) === -1) {
      return;
    }
    if (this.hooks[eventName].indexOf(hook) === -1) {
      this.hooks[eventName].push(hook);
    }
  }

  off(eventName, hook) {
    if (eventNames.indexOf(eventName) === -1) {
      return;
    }
    const index = this.hooks[eventName].indexOf(hook);
    if (index > -1) {
      this.hooks[eventName].splice(index, 1);
    }
  }

  onOpen(event) {
    console.log("WS opened");
  }

  onClose(event) {
    console.log("WS closed");
  }

  onError(event) {
    console.error(event);
    this.callHooks(this.hooks.error);
  }

  onMessage(event) {
    this.callHooks(this.hooks.data, event);
  }

  callHooks(hookFuncs, event) {
    for (const hookFunc of hookFuncs) {
      hookFunc(event);
    }
  }

  send(message) {
    if (this.connection && this.isConnected) {
      const data = JSON.stringify(message);
      this.connection.send(data);
      return true;
    }
    console.error("WS is not connected!");
    return false;
  }
}

const wsAdapter = new WSAdapter();

export default wsAdapter;

// async onMessage(event: MessageEvent) {
//  // console.log('WS message')
//  const raw_data = event.data
//  if (typeof raw_data == "string") {
//    const data = JSON.parse(raw_data)
//    const message = data.message
//    if (message.type === 'model.event') {
//      await this.updateModel(message)
//    } else {
//      console.warn("WS: unknown message type", message.type)
//      console.warn("WS message", message)
//    }

//  } else {
//    console.error('WS: message is not in the right format.')
//    console.error(raw_data)
//  }
// }

// async updateModel(message: any) {
//  if (message.model_name === 'NVRStream') {
//    this.store.commit('updateViewPassFromStream', message.model_data)
//  } else {
//    console.warn('WS: unknown model name', message.model_name)
//  }
// }
